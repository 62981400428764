import React, { ErrorInfo } from "react";

import { log } from "@/utils/logger";

import FallbackUI from "./FallbackUI";

export type ErrorBoundaryState = {
  hasError: boolean;
};

export type ErrorBoundaryProps = {
  context: string;
  children: React.ReactChild;
};

class ErrorBoundary extends React.Component<
  ErrorBoundaryProps,
  ErrorBoundaryState
> {
  constructor(props: ErrorBoundaryProps) {
    super(props);
    this.state = {
      hasError: false,
    };
  }

  public static getDerivedStateFromError(): ErrorBoundaryState {
    // Update state, so the next render will show the fallback UI.
    return { hasError: true };
  }

  public componentDidCatch(error: Error, errorInfo: ErrorInfo): void {
    // reporting service
    log.error(`${this.props.context} Error:`, error, errorInfo);
  }

  public render(): JSX.Element | React.ReactNode {
    if (this.state.hasError) {
      return (
        <FallbackUI
          onButtonClick={() => {
            this.setState({ hasError: false });
          }}
        />
      );
    }

    return this.props.children;
  }
}

export default ErrorBoundary;
