import styled from "styled-components";

import { Colors } from "@/styles/styles";

export type StyledButtonProps = {
  backgroundColor: string;
  backgroundColorHover: string;
  color: string;
  borderRadius: string;
  fontFamily: string;
  fontWeight: number;
  fontSize: string;

  disabled: {
    backgroundColor: Colors;
    color: Colors;
    fontSize: string;
    fontFamily: string;
  };
};

export const StyledButton = styled.button`
  position: relative;
  display: block;
  width: 100%;
  height: 48px;
  border: none;
  margin: 22px auto 0;
  background-color: ${(props) =>
    props.theme.components.SubmitButton.backgroundColor};
  border-radius: ${(props) => props.theme.components.SubmitButton.borderRadius};
  box-shadow: none;
  color: ${(props) => props.theme.components.SubmitButton.color};
  cursor: pointer;
  font-family: ${(props) => props.theme.components.SubmitButton.fontFamily};
  font-size: ${(props) => props.theme.components.SubmitButton.fontSize};
  font-weight: ${(props) => props.theme.components.SubmitButton.fontWeight};
  outline: none;
  text-transform: uppercase;
  &:disabled {
    background-color: ${(props) =>
      props.theme.components.SubmitButton.disabled.backgroundColor};
    color: ${(props) => props.theme.components.SubmitButton.disabled.color};
    cursor: not-allowed;
    font-family: ${(props) =>
      props.theme.components.SubmitButton.disabled.fontFamily};
    font-size: ${(props) =>
      props.theme.components.SubmitButton.disabled.fontSize};
    transition: all 100ms ease;
  }

  &:hover:not(:disabled) {
    background-color: ${(props) =>
      props.theme.components.SubmitButton.backgroundColorHover};
    transition: all 100ms ease;
  }
  @media ${(props) => props.theme.global.breakpoints.desktop} {
    margin: 0 auto;
  }
`;
