import React from "react";
import SVG from "react-inlinesvg";

import Button from "@/components/Button";
import Schedule from "@/components/Schedule";
import Text from "@/components/Text";
import { Umlauts } from "@/content/characterUnicodes";
import { ConfigContext } from "@/factory/apps/NoSSOApp";
import { Newsletter, Subscription } from "@/services/Model";
import { Icons } from "@/styles/styles";

import {
  FeaturedNewsletterCardWrapper,
  FeaturedNewsletterContentWrapper,
  FeaturedNewsletterTitle,
  ImageTitle,
  ImageTitleWrapper,
  ImageWrapper,
} from "./styles";

// This newsletter should not have a brand icon
const SAMMEL_DEIN_TEAM_NL_ID = 25042;

export type FeaturedNewsletterCardProps = Newsletter & {
  handleNewsletterClick: (selectedNewsletter: Subscription) => void;
  reverse: boolean;
};

const FeaturedNewsletterCard: React.FC<FeaturedNewsletterCardProps> = (
  props: FeaturedNewsletterCardProps,
): JSX.Element => {
  const {
    handleNewsletterClick,
    heroImageURL,
    intro,
    newsletterID,
    reverse,
    title,
    transmissionTime,
    subscribed,
  } = props;
  const { iconSourceURL, hasBrandIcon } = React.useContext(ConfigContext);
  return (
    <FeaturedNewsletterCardWrapper
      reverse={reverse}
      data-qa="FeaturedNewsletterCard"
    >
      <ImageWrapper backgroundImage={heroImageURL} reverse={reverse}>
        {hasBrandIcon && newsletterID !== SAMMEL_DEIN_TEAM_NL_ID && (
          <ImageTitleWrapper>
            <SVG src={`${iconSourceURL}${Icons.Brand}.svg`} />
            <ImageTitle>{title}</ImageTitle>
          </ImageTitleWrapper>
        )}
      </ImageWrapper>

      <FeaturedNewsletterContentWrapper>
        <FeaturedNewsletterTitle>{title}</FeaturedNewsletterTitle>
        <Schedule>{transmissionTime}</Schedule>
        <Text>{intro}</Text>
        <Button
          subscribed={subscribed}
          onClick={() => {
            handleNewsletterClick({
              newsletterID: newsletterID,
              subscribed: !subscribed,
            });
          }}
        >
          {subscribed ? `ausgew${Umlauts.ae}hlt` : `ausw${Umlauts.ae}hlen`}
        </Button>
      </FeaturedNewsletterContentWrapper>
    </FeaturedNewsletterCardWrapper>
  );
};

export default FeaturedNewsletterCard;
