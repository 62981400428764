import React from "react";

import { StyledHeadline } from "./styles";

type StyledHeadlineProps = {
  children?: React.ReactNode;
  className?: string;
  dataQa?: string;
};

const Headline: React.FC<StyledHeadlineProps> = (
  props: StyledHeadlineProps,
): JSX.Element => {
  return (
    <StyledHeadline className={props.className} data-qa={props.dataQa}>
      {props.children}
    </StyledHeadline>
  );
};

export default Headline;
