import React, { useContext } from "react";
import SVG from "react-inlinesvg";

import { ConfigContext, ContentContext } from "@/factory/apps/App";
import {
  StyledCardIntro,
  StyledCardTitle,
} from "@/sections/TopicNewsletter/TopicNewsletterCard/styles";
import { hasErrorSelector } from "@/store/application/slice";
import { useAppSelector } from "@/store/hooks";
import { Icons } from "@/styles/styles";

import StyledButton from "../StyledButton";
import { StyledErrorBoundaryWrapper } from "./styles";

type ErrorBoundaryProps = {
  children: React.ReactNode;
};

const ErrorBoundary: React.FC<ErrorBoundaryProps> = (
  props: ErrorBoundaryProps,
): JSX.Element => {
  const { ErrorBoundaryContext } = useContext(ContentContext);
  const { iconSourceURL } = useContext(ConfigContext);
  const hasError = useAppSelector(hasErrorSelector);

  if (!hasError) {
    return <>{props.children}</>;
  }

  return (
    <StyledErrorBoundaryWrapper>
      <SVG src={`${iconSourceURL}${Icons.Error}.svg`} />
      <StyledCardTitle data-qa="ErrorBoundary.Title">
        {ErrorBoundaryContext.title}
      </StyledCardTitle>
      <StyledCardIntro data-qa="ErrorBoundary.Text">
        {ErrorBoundaryContext.text}
      </StyledCardIntro>
      <StyledButton
        dataQa="ErrorBoundary.Button"
        className="inverted"
        onClick={() => {
          window.location.reload();
        }}
      >
        {ErrorBoundaryContext.buttonText}
      </StyledButton>
    </StyledErrorBoundaryWrapper>
  );
};

export default ErrorBoundary;
