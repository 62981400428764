import styled from "styled-components";

export const StyledLink = styled.a`
  color: ${(props) => props.theme.color.white} !important;
  font-family: ${(props) => props.theme.fontFamily.regular};
  font-size: ${(props) => props.theme.fontSize.m};
  line-height: ${(props) => props.theme.lineHeight.l};

  &:hover {
    cursor: pointer;
  }

  &.small {
    font-weight: ${(props) => props.theme.fontFamily.light};
    font-size: ${(props) => props.theme.fontSize.s};
    line-height: ${(props) => props.theme.lineHeight.s};
  }

  &.underlined {
    text-decoration: underline;
  }
`;
