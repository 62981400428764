import React, { ReactNode } from "react";

import { Button } from "./styles";

export type StyledButton = {
  subscribed?: boolean;
  children?: ReactNode;
  onClick?: () => void;
  dataQa?: string;
  className?: string;
};

const StyledButton: React.FC<StyledButton> = (
  props: StyledButton,
): JSX.Element => {
  return (
    <Button
      className={props.className}
      onClick={props.onClick}
      data-qa={props.dataQa}
    >
      {props.children}
    </Button>
  );
};

export default StyledButton;
