import SelectedNewsletters from "./SelectedNewsletters";
import {
  StyledSelectedNewsletterCardProps,
  StyledSelectedNewslettersHeaderProps,
  StyledSelectedNewslettersSectionProps,
  StyledSelectedNewslettersWrapperProps,
  StyledSelectedNewsletterTitleProps,
} from "./styles";

export type SelectedNewslettersStyles = {
  Section: StyledSelectedNewslettersSectionProps;
  Header: StyledSelectedNewslettersHeaderProps;
  Wrapper: StyledSelectedNewslettersWrapperProps;
  Card: StyledSelectedNewsletterCardProps;
  Title: StyledSelectedNewsletterTitleProps;
};

export default SelectedNewsletters;
