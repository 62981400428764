import React from "react";
import SVG from "react-inlinesvg";

import { ConfigContext, ContentContext } from "@/factory/apps/App";
import { showAllNewsletters } from "@/store/application/slice";
import { useAppDispatch } from "@/store/hooks";
import { Icons } from "@/styles/styles";

import { StyledShowAllButton } from "./styles";

const ShowAllButton: React.FC = () => {
  const dispatch = useAppDispatch();
  const { iconSourceURL } = React.useContext(ConfigContext);
  const {
    HeaderContext: {
      loggedIn: { showAllButton },
    },
  } = React.useContext(ContentContext);

  return (
    <StyledShowAllButton
      data-qa="Header.ShowAllButton"
      onClick={() => dispatch(showAllNewsletters())}
    >
      {showAllButton}&nbsp;
      <SVG src={`${iconSourceURL}${Icons.ArrowDown}.svg`} />
    </StyledShowAllButton>
  );
};

export default ShowAllButton;
