import React from "react";
import { useLocation } from "react-router-dom";

import SectionHeader from "@/components/SectionHeader";
import {
  ConfigContext,
  NoSSOGlobalContext,
  Views,
} from "@/factory/apps/NoSSOApp";
import Header from "@/noSSOPartials/Header";
import SelectedNewsletters from "@/noSSOPartials/SelectedNewsletters";
import { Newsletter } from "@/services/Model";

interface EditedState {
  newsletters?: Newsletter[];
  authToken?: string;
}

const Edited: React.FC = (): JSX.Element => {
  const location = useLocation<EditedState>();
  const { newsletters } = location.state || {};
  const { EditedTexts } = React.useContext(NoSSOGlobalContext);
  const { homePageURL } = React.useContext(ConfigContext);
  return (
    <React.Fragment>
      <Header
        subtitle={EditedTexts.subtitle}
        title={EditedTexts.title}
        text={EditedTexts.text}
        view={Views.Edited}
        button={{
          iconName: EditedTexts.buttonIconName,
          text: EditedTexts.buttonText,
          onButtonClick: () => window.open(homePageURL, "_self"),
        }}
      />
      {newsletters && newsletters.length > 0 && (
        <SectionHeader>{EditedTexts.sectionHeader}</SectionHeader>
      )}
      {newsletters && <SelectedNewsletters newsletters={newsletters} />}
    </React.Fragment>
  );
};

export default Edited;
