import React, { ReactNode } from "react";
import SVG from "react-inlinesvg";

import Link from "@/components/Link";
import { ConfigContext, ContentContext } from "@/factory/apps/App";
import { hideDialog, showDialogSelector } from "@/store/application/slice";
import { useAppDispatch, useAppSelector } from "@/store/hooks";
import { Icons } from "@/styles/styles";

import {
  StyledDialogContainer,
  StyledDialogContent,
  StyledDialogFooter,
  StyledDialogFooterDivider,
  StyledDialogFooterLinkWrapper,
  StyledDialogHeader,
  StyledDialogHeaderButton,
  StyledDialogWrapper,
} from "./styles";

export type DialogProps = {
  children?: ReactNode;
  dataQa?: string;
  onClick?: () => void;
};

const Dialog: React.FC<DialogProps> = (props: DialogProps): JSX.Element => {
  const dispatch = useAppDispatch();
  const { iconSourceURL } = React.useContext(ConfigContext);
  const {
    LoginDialogContext: { contactUs, telephone, privacy, imprint },
  } = React.useContext(ContentContext);

  const showDialog: boolean = useAppSelector(showDialogSelector);

  return (
    <StyledDialogContainer
      className={showDialog ? "visible" : ""}
      data-qa={props.dataQa}
    >
      <StyledDialogWrapper>
        <StyledDialogHeader>
          <SVG src={`${iconSourceURL}${Icons.Welt}.svg`} />
          <StyledDialogHeaderButton
            onClick={() => dispatch(hideDialog())}
            className="inverted"
            data-qa="Dialog.CloseButton"
          >
            <SVG src={`${iconSourceURL}${Icons.Close}.svg`} />
          </StyledDialogHeaderButton>
        </StyledDialogHeader>
        <StyledDialogContent>{props.children}</StyledDialogContent>
        <StyledDialogFooter>
          <p>
            <b>{contactUs}</b> {telephone}
          </p>
          <StyledDialogFooterLinkWrapper>
            <Link
              href="https://www.welt.de/datenschutz/"
              target="_blank"
              className="small"
              dataQa="Dialog.PrivacyLink"
            >
              {privacy}
            </Link>
            <StyledDialogFooterDivider> | </StyledDialogFooterDivider>
            <Link
              href="https://www.welt.de/impressum/"
              target="_blank"
              className="small"
              dataQa="Dialog.ImprintLink"
            >
              {imprint}
            </Link>
          </StyledDialogFooterLinkWrapper>
        </StyledDialogFooter>
      </StyledDialogWrapper>
    </StyledDialogContainer>
  );
};

export default Dialog;
