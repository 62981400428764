import { WhoamiWrapper } from "@weltn24/la-frontend-utils";
import React from "react";

import { ContentContext } from "@/factory/apps/App";
import {
  StyledHeaderHeadline,
  StyledHeaderParagraph,
} from "@/sections/Header/styles";

import Dialog from "../Dialog";
import StyledButton from "../StyledButton";

const LoginDialog: React.FC = (): JSX.Element => {
  const whoami = WhoamiWrapper.getInstance();
  const doLogin = () => whoami.doLogin();
  const doRegister = () => whoami.doRegister();
  const {
    LoginDialogContext: { title, introRegister, introLogin, register, login },
  } = React.useContext(ContentContext);

  return (
    <Dialog dataQa="Dialog">
      <StyledHeaderHeadline data-qa="Dialog.Headline">
        {" "}
        {title}{" "}
      </StyledHeaderHeadline>
      <StyledHeaderParagraph data-qa="Dialog.Intro">
        {introRegister}
      </StyledHeaderParagraph>
      <StyledButton dataQa="Dialog.RegisterButton" onClick={() => doRegister()}>
        {register}
      </StyledButton>
      <StyledHeaderParagraph data-qa="Dialog.IntroLogin">
        {introLogin}
      </StyledHeaderParagraph>
      <StyledButton
        dataQa="Dialog.LoginButton"
        onClick={() => doLogin()}
        className="inverted"
      >
        {login}
      </StyledButton>
    </Dialog>
  );
};

export default LoginDialog;
