import styled from "styled-components";

import { Colors, FontFamily } from "@/styles/styles";

export type FeaturedNewsletterCardWrapperProps = {
  border: string;
  borderRadius: string;
  height: string;
  marginBottom: string;

  desktop: {
    height: string;
    flexDirection: string;
  };
};

export const FeaturedNewsletterCardWrapper = styled.div<{ reverse?: boolean }>`
  display: flex;
  overflow: hidden;
  width: 100%;
  height: ${(props) => props.theme.featuredNewsletters.Card.CardWrapper.height};
  flex-direction: column;
  border: ${(props) => props.theme.featuredNewsletters.Card.CardWrapper.border};
  margin-bottom: ${(props) =>
    props.theme.featuredNewsletters.Card.CardWrapper.marginBottom};
  border-radius: ${(props) =>
    props.theme.featuredNewsletters.Card.CardWrapper.borderRadius};

  @media ${(props) => props.theme.global.breakpoints.desktop} {
    height: ${(props) =>
      props.theme.featuredNewsletters.Card.CardWrapper.desktop.height};
    flex-direction: ${(props) =>
      props.reverse
        ? props.theme.featuredNewsletters.Card.CardWrapper.desktop.flexDirection
        : "row"};
  }
`;

export type ImageWrapperProps = {
  backgroundColor: Colors;
  borderRadius: string;
  height: string;
  width: string;

  desktop: {
    height: string;
    width: string;
  };
};
export const ImageWrapper = styled.div<{
  backgroundImage?: string;
  reverse?: boolean;
}>`
  display: flex;
  width: ${(props) => props.theme.featuredNewsletters.Card.ImageWrapper.width};
  height: ${(props) =>
    props.theme.featuredNewsletters.Card.ImageWrapper.height};
  flex-shrink: 0;
  align-items: center;
  justify-content: center;
  background-color: ${(props) =>
    props.theme.featuredNewsletters.Card.ImageWrapper.backgroundColor};
  background-image: url("${(props) => props.backgroundImage}");
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;

  @media ${(props) => props.theme.global.breakpoints.desktop} {
    width: ${(props) =>
      props.theme.featuredNewsletters.Card.ImageWrapper.desktop.width};
    height: ${(props) =>
      props.theme.featuredNewsletters.Card.ImageWrapper.desktop.height};
  }
`;

export type FeaturedNewsletterContentWrapperProps = {
  padding: string;
};
export const FeaturedNewsletterContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: ${(props) =>
    props.theme.featuredNewsletters.Card.ContentWrapper.padding};
  text-align: center;
`;

export const ImageTitleWrapper = styled.div`
  display: ${(props) =>
    props.theme.featuredNewsletters.Card.ImageTitle.display};
  align-items: center;
  justify-content: center;
`;

export type ImageTitleProps = {
  display: string;
  border: string;
  color: Colors;
  fontFamily: FontFamily;
  fontSize: string;
  fontWeight: number;
  lineHeight: string;
  margin: string;
  padding: string;
};
export const ImageTitle = styled.div`
  padding: ${(props) =>
    props.theme.featuredNewsletters.Card.ImageTitle.padding};
  border: ${(props) => props.theme.featuredNewsletters.Card.ImageTitle.border};
  margin-left: ${(props) =>
    props.theme.featuredNewsletters.Card.ImageTitle.margin};
  box-shadow: 0 0 16px 0 rgba(0, 0, 0, 70%);
  color: ${(props) => props.theme.featuredNewsletters.Card.ImageTitle.color};
  font-family: ${(props) =>
    props.theme.featuredNewsletters.Card.ImageTitle.fontFamily};
  font-size: ${(props) =>
    props.theme.featuredNewsletters.Card.ImageTitle.fontSize};
  font-weight: ${(props) =>
    props.theme.featuredNewsletters.Card.ImageTitle.fontWeight};
  line-height: ${(props) =>
    props.theme.featuredNewsletters.Card.ImageTitle.lineHeight};
  text-align: center;
  text-shadow: 0 0 16px rgba(0, 0, 0, 70%);
  text-transform: uppercase;
`;

export type FeaturedNewsletterTitleProps = {
  color: Colors;
  fontFamily: FontFamily;
  fontSize: string;
  fontWeight: number;
  lineHeight: string;
};

export const FeaturedNewsletterTitle = styled.h4`
  color: ${(props) => props.theme.featuredNewsletters.Card.Title.color};
  font-family: ${(props) =>
    props.theme.featuredNewsletters.Card.Title.fontFamily};
  font-size: ${(props) => props.theme.featuredNewsletters.Card.Title.fontSize};
  font-weight: ${(props) =>
    props.theme.featuredNewsletters.Card.Title.fontWeight};
  line-height: ${(props) =>
    props.theme.featuredNewsletters.Card.Title.lineHeight};
`;
