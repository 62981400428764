import React from "react";

import AppFactory from "@/factory/AppFactory";

import { getBrandIdentifier } from "./Brands";

const App: React.FC = (): JSX.Element => (
  <AppFactory brand={getBrandIdentifier()} />
);

export default App;
