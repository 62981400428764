import React from "react";

import { StyledChip } from "./styles";

type ChipProps = {
  onClick: (event: React.MouseEvent<HTMLAnchorElement>) => void;
  children?: React.ReactNode;
  href: string;
  dataQa?: string;
};

const Chip: React.FC<ChipProps> = (props: ChipProps): JSX.Element => {
  const { children, href, onClick, dataQa } = props;

  return (
    <StyledChip href={href} onClick={onClick} data-qa={dataQa}>
      {children}
    </StyledChip>
  );
};

export default Chip;
