import React, { useState } from "react";
import SVG from "react-inlinesvg";

import Button from "@/components/Button";
import { Umlauts } from "@/content/characterUnicodes";
import { ConfigContext } from "@/factory/apps/NoSSOApp";
import { Newsletter, Subscription } from "@/services/Model";
import { Icons } from "@/styles/styles";

import Infobox from "./OthernewslettersInfobox";
import InfoButton from "./OtherNewslettersInfoButton";
import {
  Card,
  IconWrapper,
  ImageWrapper,
  Schedule,
  ScheduleIconWrapper,
  ScheduleWrapper,
  Sponsored,
  Text,
  TextWrapper,
  Title,
  TitleWrapper,
} from "./styles";

export type CardProps = Newsletter & {
  text?: string;
  subscribed?: boolean;
  sponsored?: boolean;
  newsletterIcon?: string;
  handleNewsletterClick: (selectedNewsletter: Subscription) => void;
};

export type SubscribedState = {
  subscribed: boolean;
};

const OtherNewslettersCard: React.FC<CardProps> = (
  props: CardProps,
): JSX.Element => {
  const {
    intro,
    transmissionTime,
    subscribed,
    sponsored,
    title,
    newsletterID,
    imageURL,
    handleNewsletterClick,
  } = props;

  const { iconSourceURL, hasCardBackgroundImage } =
    React.useContext(ConfigContext);

  const [isVisible, setIsVisible] = useState(false);

  // COBI changed to using images instead of icons for other newsletters cards
  // We still need to support icons for BILD and AUBI
  const setImageOrIcon = () => {
    if (hasCardBackgroundImage) {
      return <ImageWrapper backgroundImage={imageURL} />;
    } else {
      return (
        <IconWrapper>
          {imageURL ? (
            <img src={imageURL} />
          ) : (
            <SVG src={`${iconSourceURL}${Icons.FallbackSVG}.svg`} />
          )}
        </IconWrapper>
      );
    }
  };

  return (
    <Card data-qa="OtherNewsletterCard">
      {sponsored && <Sponsored>ANZEIGE</Sponsored>}
      <Infobox
        text={intro}
        isVisible={isVisible}
        onClick={() => setIsVisible(false)}
      />
      {setImageOrIcon()}
      <TextWrapper>
        <TitleWrapper>
          <Title>{title}</Title>
          <InfoButton onClick={() => setIsVisible(true)} />
        </TitleWrapper>
        <ScheduleWrapper>
          <ScheduleIconWrapper>
            <SVG src={`${iconSourceURL}${Icons.Clock}.svg`} />
          </ScheduleIconWrapper>
          <Schedule>{transmissionTime}</Schedule>
        </ScheduleWrapper>
        <Text>{intro}</Text>
        <Button
          subscribed={subscribed}
          onClick={() => {
            handleNewsletterClick({
              newsletterID: newsletterID,
              subscribed: !subscribed,
            });
          }}
        >
          {subscribed ? `ausgew${Umlauts.ae}hlt` : `ausw${Umlauts.ae}hlen`}
        </Button>
      </TextWrapper>
    </Card>
  );
};

export default OtherNewslettersCard;
