import React, { ReactNode } from "react";

import { Views } from "@/factory/apps/NoSSOApp";

import { SectionHeadline, SectionHeadlineBorder } from "./styles";

type SectionHeaderProps = {
  children: ReactNode;
  view?: Views;
};
const SectionHeader: React.FC<SectionHeaderProps> = (
  props: SectionHeaderProps,
): JSX.Element => (
  <React.Fragment>
    <SectionHeadline view={props.view}>{props.children}</SectionHeadline>
    <SectionHeadlineBorder />
  </React.Fragment>
);

export default SectionHeader;
