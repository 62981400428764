import { DefaultTheme } from "styled-components";

import { rem } from "../../utils/Css";
import { Colors, FontFamily } from "../styles";

export const breakpoints = {
  mobileSmall: `(min-width: 0px) and (max-width: 360px)`,
  mobile: `(min-width: 361px) and (max-width: 745px)`,
  desktop: `(min-width: 745px) and (max-width: 3840px)`,
};

enum FontWeight {
  Normal = 400,
  Bold = 700,
  Heavy = 900,
}

enum BorderRadius {
  Primary = "8px",
  Secondary = "4px",
}

enum BorderWidth {
  Primary = "2px",
}

// create the actual theme here based on the definition in style.d.ts
export const bild: DefaultTheme = {
  global: {
    breakpoints: breakpoints,
    background: Colors.White,
    colors: {
      black: Colors.Black,
    },
    fonts: {
      fontFamily: FontFamily.GothamXNarrow,
      fontFamilyCondensed: FontFamily.GothamCondensed,
      fontFamilyExtra: FontFamily.Gotham,
    },
    transitions: {
      duration: "100ms",
      speedCurve: "ease-in",
    },
  },

  // basic components:
  components: {
    Buttons: {
      Basic: {
        borderWidth: "2px",
        borderStyle: "solid",
        borderColor: Colors.Grey,
        borderRadius: BorderRadius.Primary,
        width: "100%",
        height: "48px",
        backgroundColor: Colors.Transparent,
        color: Colors.DarkGrey,
        fontSize: rem(17),
        fontFamily: FontFamily.GothamXNarrow,
        fontWeight: FontWeight.Heavy,
        letterSpacing: "0.03em",
        desktop: {
          height: rem(48),
        },

        hover: {
          backgroundColor: Colors.ButtonHoverBackground,
          borderColor: Colors.Black,
          color: Colors.Black,
        },
        subscribed: {
          backgroundColor: Colors.Transparent,
          borderColor: Colors.Green,
          color: Colors.Green,
        },
        checkmark: {
          color: Colors.Green,
        },
      },
      Link: {
        display: "flex",
        width: "100%",
        marginTop: "24px",
        padding: "0px",
        maxWidth: "336px",
        flexDirection: "row",
        desktop: {
          height: rem(48),
          fontSize: rem(16),
        },
        icon: {
          margin: "0 20px 0 0",
        },
      },
    },

    SectionHeader: {
      Headline: {
        display: "flex",
        color: Colors.Black,
        fontFamily: FontFamily.GothamCondensed,
        fontSize: rem(32),
        fontWeight: FontWeight.Heavy,
        lineHeight: rem(42),

        desktop: {
          fontSize: rem(40),
          lineHeight: rem(40),
        },
      },
      Border: {
        display: "block",
        borderColor: Colors.Primary,
        marginTop: "12px",
        height: "4px",
        width: "54px",

        desktop: {
          marginTop: "16px",
        },
      },
    },
    Schedule: {
      Text: {
        color: Colors.Primary,
        fontFamily: FontFamily.GothamXNarrow,
        fontSize: rem(16),
        fontWeight: FontWeight.Bold,
        lineHeight: rem(23),
        marginLeft: "10px",
        desktop: {
          fontSize: rem(18),
        },
      },

      Wrapper: {
        marginTop: "4px",
      },
    },
    Text: {
      color: Colors.Black,
      fontFamily: FontFamily.GothamXNarrow,
      fontSize: rem(18),
      lineHeight: rem(23),
      margin: "12px 0",
    },
    InputField: {
      borderRadius: BorderRadius.Primary,
      borderColor: Colors.Grey,
      borderWidth: BorderWidth.Primary,
      backgroundColor: Colors.White,
      color: Colors.DarkGrey,
      fontFamily: FontFamily.GothamXNarrow,
      fontSize: "17px",

      hover: {
        borderColor: Colors.DarkGrey,
        color: Colors.Black,
      },

      focus: {
        borderColor: Colors.Black,
        color: Colors.Black,
      },

      error: {
        borderColor: Colors.Primary,
        color: Colors.Primary,
      },

      disabled: {
        borderColor: Colors.Grey,
        color: Colors.Grey,
      },
    },
    SubmitButton: {
      backgroundColor: Colors.Green,
      backgroundColorHover: Colors.DarkGreen,
      color: Colors.White,
      borderRadius: BorderRadius.Primary,
      fontFamily: FontFamily.GothamXNarrow,
      fontWeight: FontWeight.Bold,
      fontSize: rem(17),

      disabled: {
        backgroundColor: Colors.LightGrey,
        color: Colors.DisabledButtonColor,
        fontSize: rem(17),
        fontFamily: FontFamily.GothamXNarrow,
      },
    },
    CheckBox: {
      borderWidth: BorderWidth.Primary,
      borderRadius: BorderRadius.Secondary,
      borderColor: Colors.DarkGrey,
    },
    FinePrint: {
      fontFamily: FontFamily.GothamXNarrow,
      fontSize: "16px",
      fontStyle: "normal",
      fontWeight: FontWeight.Normal,
      lineHeight: "21px",
    },
  },

  // sections:
  mainContent: {
    maxWidth: "700px;",
  },

  header: {
    DescriptionHeadline: {
      lineHeight: "130%",
      fontWeight: "bold",
      fontStyle: "normal",
      fontSize: "18px",
    },
    DescriptionList: {
      fontSize: "18px",
      padding: "8px 0px 0px 0px",
      gap: "8px",
    },
    Description: {
      gap: "13px",
      alignItems: "center",
      justifyContent: "space-between",
    },
    HeaderBackground: {
      backgroundColor: Colors.Primary,
    },
    HeaderHeadline: {
      width: "100%",
      textAlign: "left",
      desktop: {
        padding: "64px 0 0",
      },
    },
    Headline: {
      color: Colors.White,
      fontSize: rem(36),
      fontWeight: FontWeight.Heavy,
      fontFamily: FontFamily.Gotham,
      lineHeight: rem(43),
      width: "100%",
      margin: "0 0 80px",

      desktop: {
        fontSize: rem(60),
        lineHeight: rem(62),
        width: "100%",
      },
      mobile: {
        margin: "0 0 20px",
      },
    },
    Subtitle: {
      backgroundColor: Colors.Transparent,
      color: Colors.White,
      padding: "0 0px",
      fontSize: rem(30),
      lineHeight: rem(36),
      fontWeight: FontWeight.Normal,
      fontFamily: FontFamily.GothamXNarrow,

      desktop: {
        fontSize: rem(44),
        lineHeight: rem(58),
      },
    },
    TextWrapper: {
      borderRadius: BorderRadius.Primary,
      boxShadow: "0px 8px 24px rgba(33, 37, 41, 0.1)",
      backgroundColor: Colors.White,
      padding: "24px 12px",
      mobile: {
        width: "auto",
        height: "auto",
        padding: "12px",
      },
    },
    TextProps: {
      fontSize: rem(18),
      lineHeight: rem(23),
      color: Colors.Black,
      fontFamily: FontFamily.GothamXNarrow,
    },
  },

  registrationBox: {
    Headline: {
      fontSize: rem(24),
      fontWeight: FontWeight.Heavy,
      fontFamily: FontFamily.GothamCondensed,
      desktop: {
        fontSize: rem(24),
      },
    },
    Wrapper: {
      backgroundColor: Colors.White,
    },
    Box: {
      backgroundColor: Colors.White,
      borderColor: Colors.Grey,
      borderRadius: BorderRadius.Primary,
      borderWidth: BorderWidth.Primary,
    },
    OptIn: {
      padding: "28px 0 12px",
      desktop: {
        padding: "29px 0 12px",
      },
    },
  },

  featuredNewsletters: {
    Header: {
      alignSelf: "center",
      margin: "29px 0 16px",
      desktop: {
        alignSelf: "center",
        margin: "40px 0 28px",
      },
    },
    Card: {
      CardWrapper: {
        border: "2px solid " + Colors.Grey,
        borderRadius: BorderRadius.Primary,
        height: "413px",
        marginBottom: "25px",

        desktop: {
          height: "242px",
          flexDirection: "row-reverse",
        },
      },
      ContentWrapper: {
        padding: "16px",
      },
      ImageWrapper: {
        borderRadius: BorderRadius.Primary,
        height: "198px",
        width: "100%",
        backgroundColor: Colors.Grey,

        desktop: {
          height: "100%",
          width: "430px",
        },
      },
      ImageTitle: {
        display: "flex",
        border: "2px solid " + Colors.White,
        padding: "7px",
        color: Colors.White,
        fontFamily: FontFamily.GothamXNarrow,
        fontWeight: FontWeight.Bold,
        fontSize: rem(24),
        lineHeight: rem(28),
        margin: "10px",
      },
      Title: {
        color: Colors.Black,
        fontFamily: FontFamily.GothamCondensed,
        fontWeight: FontWeight.Heavy,
        fontSize: rem(36),
        lineHeight: rem(36),
      },
    },
  },

  otherNewsletters: {
    Header: {
      display: "flex",
      margin: "15px 0 10px",
      desktop: {
        margin: "16px 0 8px",
      },
    },
    Section: {
      justifyContent: "start",
      width: "calc((162px * 2) + 12px)",
    },
    Card: {
      CardStyle: {
        backgroundColor: Colors.White,
        borderWidth: BorderWidth.Primary,
        borderColor: Colors.Grey,
        borderRadius: BorderRadius.Primary,
        width: "162px",
        minHeight: "217px",
        margin: "6px",
        padding: "12px 7.5px",

        desktop: {
          height: "299px",
          heightLastCard: "299px",
          margin: "8px",
          width: "222.6px",
          padding: "12px 8px",
        },
        mobileSmall: {
          minHeight: "140px",
          padding: "12px 8px",
        },
      },
      Sponsored: {
        top: "-13px",
        color: Colors.DarkGrey,
        fontSize: "9px",
        fontFamily: FontFamily.Gotham,
        desktop: {
          top: "-14px",
          fontSize: "10px",
        },
      },
      TextWrapper: {
        padding: "0px",
        mobileSmall: {
          alignItems: "flex-start",
          padding: "0 25px 0 0",
        },
        desktop: {
          padding: "0px",
        },
      },
      TitleWrapper: {
        mobileSmall: {
          minHeight: "28px",
        },
      },
      IconWrapper: {
        imgWidth: "80px",
        imgHeight: "80px",
        desktop: {
          margin: "0 0 8px 0",
          imgWidth: "80px",
          imgHeight: "80px",
        },
        mobileSmall: {
          marginRight: "24px",
        },
      },
      Title: {
        margin: "0 6px 0 0",
        color: Colors.Black,
        fontFamily: FontFamily.GothamXNarrow,
        fontSize: "21px",
        desktop: {
          fontSize: rem(21),
        },
      },
      InfoIconButton: {
        display: "inline",
        width: "24px",
        height: "24px",
      },
      InfoboxModal: {
        fontSize: "16px",
        padding: "11px 12px",
      },
      Schedule: {
        color: Colors.Primary,
        fontFamily: FontFamily.GothamXNarrow,
        fontSize: "16px",
        fontWeight: FontWeight.Bold,
        margin: "0 0 8px 0",
        desktop: {
          fontSize: rem(16),
        },
        mobileSmall: {
          fontSize: rem(16),
        },
      },
      Text: {
        color: Colors.Black,
        fontFamily: FontFamily.GothamXNarrow,
        fontSize: "16px",
        lineHeight: "23px",
        textAlign: "center",
        desktop: {
          padding: "0px 0px",
        },
      },
    },
  },

  selectedNewsletters: {
    Section: {
      desktop: {
        maxWidth: "712px",
        padding: "0px",
      },
    },
    Header: {
      margin: "32px 0 16px",
      desktop: {
        margin: "40px 0 16px",
      },
    },
    Wrapper: {
      padding: "0 20px",
    },
    Card: {
      border: "2px solid " + Colors.Grey,
      borderRadius: BorderRadius.Primary,
      desktop: {
        maxWidth: "225px",
        height: "76px",
        alignItems: "start",
      },
    },
    Title: {
      color: Colors.Black,
      fontFamily: FontFamily.GothamXNarrow,
      fontSize: rem(21),
      fontWeight: FontWeight.Bold,
      lineHeight: rem(27),
      desktop: {
        textAlign: "left",
        fontSize: rem(21),
        lineHeight: rem(27),
      },
    },
  },
} as DefaultTheme;
