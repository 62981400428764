import React from "react";

import {
  weltTopicHeadlineMapper,
  weltTopicOrder,
  WeltTopics,
} from "@/brandConfig/configs";
import Chip from "@/components/Chip";
import Headline from "@/components/Headline";
import { ContentContext } from "@/factory/apps/App";

import { StyledChipWrapper, StyledWrapper } from "./styles";

const handleClick = (event: React.MouseEvent<HTMLAnchorElement>) => {
  event.preventDefault();
  const targetId = event.currentTarget.getAttribute("href");
  if (!targetId) {
    return;
  }

  const targetElement = document.querySelector(targetId);
  if (!targetElement) {
    return;
  }

  targetElement.scrollIntoView({
    behavior: "smooth",
  });
};

const Navigation: React.FC = (): JSX.Element => {
  const { NavigationContext } = React.useContext(ContentContext);

  return (
    <StyledWrapper data-qa="Navigation.Wrapper">
      <Headline className="m" dataQa="Navigation.Headline">
        {NavigationContext.headline}
      </Headline>
      <StyledChipWrapper data-qa="Navigation.ChipWrapper">
        {weltTopicOrder.map((topic: WeltTopics, index: number) => (
          <Chip
            dataQa="Navigation.Chip"
            key={`navigation-chip-${index}`}
            href={`#${topic}-wrapper`}
            onClick={handleClick}
          >
            {weltTopicHeadlineMapper[topic]}
          </Chip>
        ))}
      </StyledChipWrapper>
    </StyledWrapper>
  );
};

export default Navigation;
