import React, { ReactNode } from "react";
import SVG from "react-inlinesvg";

import { ConfigContext } from "@/factory/apps/NoSSOApp";
import { Icons } from "@/styles/styles";

import {
  BasicButton,
  ButtonText,
  StyledCheckmark,
  StyledWrapper,
} from "./styles";

export type ButtonProps = {
  subscribed?: boolean;
  children?: ReactNode;
  onClick?: () => void;
};

const Button: React.FC<ButtonProps> = (props: ButtonProps): JSX.Element => {
  const { iconSourceURL } = React.useContext(ConfigContext);
  return (
    <BasicButton subscribed={props.subscribed} onClick={props.onClick}>
      <StyledWrapper>
        <StyledCheckmark subscribed={props.subscribed}>
          <SVG src={`${iconSourceURL}${Icons.Checkmark}.svg`} />
        </StyledCheckmark>
        <ButtonText subscribed={props.subscribed}>{props.children}</ButtonText>
      </StyledWrapper>
    </BasicButton>
  );
};

export default Button;
