import { css, keyframes } from "styled-components";

export const blockCenterRelative = css`
  position: relative;
  display: block;
  margin: 0 auto;
`;

export const flexColumn = css`
  display: flex;
  flex-direction: column;
`;

export const SpinningAnimation = keyframes`
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
`;

export const fadeInAnimation = keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
`;

export const expandAnimation = (closedHeight: number) => keyframes`
  from {
    max-height: ${closedHeight}px;
  }
  to {
    max-height: 1332px;
  }
`;

export const fadeOut = keyframes`
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
`;

export const pulse = keyframes`
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.1);
  }
  100% {
    transform: scale(1);
  }
`;
