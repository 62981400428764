import {
  AnyAction,
  CombinedState,
  configureStore,
  Dispatch,
} from "@reduxjs/toolkit";
import { ToolkitStore } from "@reduxjs/toolkit/dist/configureStore";
import { Api } from "@weltn24/la-frontend-utils";
import createSagaMiddleware, { SagaMiddleware } from "redux-saga";

import { getApiUrl } from "@/apiConfig";
import NoSSOPaperboyApi, {
  INoSSOPaperboyApi,
} from "@/services/api/NoSSOPaperboyApi";
import { isProduction } from "@/services/Environment";
import { log } from "@/utils/logger";

import { NewsletterState } from "./newsletter/slice";
import rootReducer from "./rootReducer";
import rootSaga from "./rootSaga";
import { UserState } from "./user/slice";

// create saga middleware
const sagaMiddleware = createSagaMiddleware({
  onError: (error: Error, { sagaStack }) => {
    log.error(`Uncaught Saga error: ${error.toString()}`, { sagaStack });
  },
});

export const createStore = (): ToolkitStore<
  CombinedState<{
    user: UserState;
    newsletter: NewsletterState;
  }>,
  AnyAction,
  SagaMiddleware<object>[]
> => {
  const store = configureStore({
    devTools: !isProduction(),
    reducer: rootReducer,
    middleware: [sagaMiddleware],
  });

  const paperboyApi: INoSSOPaperboyApi = NoSSOPaperboyApi(new Api(getApiUrl()));

  sagaMiddleware.run(rootSaga, paperboyApi);

  return store;
};

export type RootState = ReturnType<typeof rootReducer>;
export type AppDispatch = Dispatch;
