import styled from "styled-components";

export const StyledNewsletterBanner = styled.div`
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: center;
  height: 20px;

  background-color: ${(props) => props.theme.color.white};
  border-top-right-radius: ${(props) => props.theme.radius.s};

  &.absolute-bottom-left {
    position: absolute;
    padding-right: 8px;
    bottom: -14px;
    left: -1px;
    height: 36px;
  }
`;

export const StyledSVGSpan = styled.div`
  display: flex;
  margin-right: ${(props) => props.theme.margin.s};
`;

export const StyledNewsletterText = styled.span`
  text-transform: uppercase;

  color: ${(props) => props.theme.color.text};
  line-height: ${(props) => props.theme.lineHeight.s};
  font-size: ${(props) => props.theme.fontSize.s};
  font-family: ${(props) => props.theme.fontFamily.heavy};
`;
