import { all, setContext } from "redux-saga/effects";

import { INoSSOPaperboyApi } from "@/services/api/NoSSOPaperboyApi";

import { watchFetchNewsletters, watchSubmitting } from "./newsletter/sagas";
import { watchFetchUserInfo } from "./user/sagas";

/** start all sagas */
export default function* rootSaga(p: INoSSOPaperboyApi): Generator {
  yield setContext({ paperboyApi: p });
  yield all([watchFetchUserInfo(), watchFetchNewsletters(), watchSubmitting()]);
}
