import styled from "styled-components";

export type StyledFinePrintProps = {
  fontFamily: string;
  fontSize: string;
  fontWeight: number;
  fontStyle: string;
  lineHeight: string;
};

export const StyledFinePrint = styled.p`
  font-family: ${(props) => props.theme.components.FinePrint.fontFamily};
  font-size: ${(props) => props.theme.components.FinePrint.fontSize};
  font-style: ${(props) => props.theme.components.FinePrint.fontStyle};
  font-weight: ${(props) => props.theme.components.FinePrint.fontWeight};
  line-height: ${(props) => props.theme.components.FinePrint.lineHeight};
`;
