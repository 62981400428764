import styled from "styled-components";

import { Colors, FontFamily } from "@/styles/styles";

import { SectionHeaderWrapper } from "../FeaturedNewsletters/styles";

export type StyledSelectedNewslettersSectionProps = {
  desktop: {
    maxWidth: string;
    padding?: string;
  };
};
export const SelectedNewslettersSection = styled.section`
  display: flex;
  width: 100%;
  flex-direction: column;
  flex-wrap: wrap;
  align-items: center;
  padding: 0 20px;

  @media ${(props) => props.theme.global.breakpoints.desktop} {
    max-width: ${(props) =>
      props.theme.selectedNewsletters.Section.desktop.maxWidth};
    padding: ${(props) =>
      props.theme.selectedNewsletters.Section.desktop?.padding};
    margin: 0 auto;
  }
`;
export type StyledSelectedNewslettersHeaderProps = {
  margin: string;
  desktop: {
    margin: string;
  };
};
export const SelectedNewslettersHeaderWrapper = styled(SectionHeaderWrapper)`
  margin: ${(props) => props.theme.selectedNewsletters.Header.margin};
  @media ${(props) => props.theme.global.breakpoints.desktop} {
    margin: ${(props) => props.theme.selectedNewsletters.Header.desktop.margin};
  }
`;

export type StyledSelectedNewslettersWrapperProps = {
  padding: string;
};
export const SelectedNewslettersWrapper = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  flex-wrap: wrap;
  align-items: center;
  padding: ${(props) => props.theme.selectedNewsletters.Wrapper.padding};

  @media ${(props) => props.theme.global.breakpoints.desktop} {
    flex-direction: row;
    padding: 0;
  }
`;
export type StyledSelectedNewsletterCardProps = {
  border: string;
  borderRadius: string;
  desktop: {
    maxWidth: string;
    height: string;
    alignItems: string;
  };
};
export const SelectedNewsletterCard = styled.div`
  display: flex;
  width: 100%;
  height: 76px;
  flex-direction: column;
  justify-content: center;
  padding: 12px;
  border: ${(props) => props.theme.selectedNewsletters.Card.border};
  margin-bottom: 12px;
  border-radius: ${(props) =>
    props.theme.selectedNewsletters.Card.borderRadius};

  @media ${(props) => props.theme.global.breakpoints.desktop} {
    max-width: ${(props) =>
      props.theme.selectedNewsletters.Card.desktop.maxWidth};
    height: ${(props) => props.theme.selectedNewsletters.Card.desktop.height};
    align-items: ${(props) =>
      props.theme.selectedNewsletters.Card.desktop.alignItems};
    margin-right: 6px;
    margin-left: 6px;
  }
`;

export type StyledSelectedNewsletterTitleProps = {
  fontSize: string;
  fontFamily: FontFamily;
  lineHeight: string;
  fontWeight: number;
  color: Colors;
  desktop: {
    textAlign: string;
    fontSize: string;
    lineHeight: string;
  };
};
export const SelectedNewsletterTitle = styled.p`
  color: ${(props) => props.theme.selectedNewsletters.Title.color};
  font-family: ${(props) => props.theme.selectedNewsletters.Title.fontFamily};
  font-size: ${(props) => props.theme.selectedNewsletters.Title.fontSize};
  font-weight: ${(props) => props.theme.selectedNewsletters.Title.fontWeight};
  line-height: ${(props) => props.theme.selectedNewsletters.Title.lineHeight};

  @media ${(props) => props.theme.global.breakpoints.desktop} {
    font-size: ${(props) => props.theme.selectedNewsletters.Title.fontSize};
    line-height: ${(props) => props.theme.selectedNewsletters.Title.lineHeight};
    text-align: ${(props) =>
      props.theme.selectedNewsletters.Title.desktop.textAlign};
  }
`;
