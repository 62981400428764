import React from "react";

import { StyledFinePrint } from "./styles";

export type FinePrintProps = {
  children: React.ReactChild;
};

const FinePrint: React.FC<FinePrintProps> = (
  props: FinePrintProps,
): JSX.Element => <StyledFinePrint>{props.children}</StyledFinePrint>;

export default FinePrint;
