import React from "react";
import SVG from "react-inlinesvg";

import { ConfigContext } from "@/factory/apps/App";
import { Icons } from "@/styles/styles";

import { StyledLoadingAnimationWrapper } from "./styles";

type LoaderUntilProps = {
  condition: boolean;
  children: JSX.Element;
  loadingDescription?: string;
  className?: string;
};

export const LoaderUntil: React.FC<LoaderUntilProps> = (
  props: LoaderUntilProps,
): JSX.Element => {
  const { children, condition, loadingDescription, className } = props;
  const { iconSourceURL } = React.useContext(ConfigContext);

  return (
    <div style={{ textAlign: "center" }}>
      {condition ? (
        children
      ) : (
        <StyledLoadingAnimationWrapper className={className}>
          <SVG
            src={`${iconSourceURL}${Icons.Spinner}.svg`}
            width={25}
            height={25}
          />
          {loadingDescription}
        </StyledLoadingAnimationWrapper>
      )}
    </div>
  );
};
