import styled from "styled-components";

export type StyledCheckBoxProps = {
  borderWidth: string;
  borderRadius: string;
  borderColor: string;
};

export const StyledCheckBox = styled.input.attrs({ type: "checkbox" })`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: block;
  width: 24px;
  height: 24px;
  cursor: pointer;
  opacity: 0;

  &:disabled {
    cursor: not-allowed;
  }
`;

export const StyledCheckBoxLabel = styled.label`
  position: relative;
  display: inline-block;
  width: 24px;
  height: 24px;
  box-sizing: border-box;
  border: ${(props) => props.theme.components.CheckBox.borderWidth} solid
    ${(props) => props.theme.components.CheckBox.borderColor};
  border-radius: ${(props) => props.theme.components.CheckBox.borderRadius};

  svg {
    display: block;
    margin: 0 auto;
  }
`;

export const StyledCheckBoxWrap = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  align-items: center;
  justify-content: center;
`;
