import React, { ReactNode } from "react";

import { TextComponent } from "./styles";

type TextProps = {
  children: ReactNode;
};
const Text: React.FC<TextProps> = (props: TextProps): JSX.Element => (
  <TextComponent>{props.children}</TextComponent>
);

export default Text;
