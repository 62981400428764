import { LaLogger, LoggerFactory, LogLevel } from "@weltn24/la-client-logging";

import { Environment, environment } from "@/services/Environment";

const createLoggerConfig = (): { [key: string]: LogLevel | boolean } => {
  const env: Environment = environment();

  if (env === Environment.Development) {
    return {
      logLevel: LogLevel.Debug,
      forceLogAggregation: false, // means don't push to our log lambda, which would log to cloudfront
      forceLogConsole: true,
    };
  }

  // default is production, so in case something is wrong
  // we only log the critical stuff and don't push to many logs to our logging lambda.
  return {
    logLevel: LogLevel.Error,
    forceLogAggregation: true,
    forceLogConsole: true,
  };
};

export const log: LaLogger = LoggerFactory(
  "la-paperboy-web",
  createLoggerConfig(),
);
