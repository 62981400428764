import React from "react";

import LinkButton from "@/components/LinkButton";
import { ButtonWrapper } from "@/components/LinkButton/styles";
import { Views } from "@/factory/apps/NoSSOApp";

import { HeaderTextWrapper, Text } from "./styles";

export type HeaderTextProps = {
  text?: string;
  button?: LinkButtonProps;
  view: Views;
};

export type LinkButtonProps = {
  iconName?: string;
  text: string;
  onButtonClick: () => void;
};

const HeaderText: React.FC<HeaderTextProps> = (
  props: HeaderTextProps,
): JSX.Element => {
  if (props.button !== undefined) {
    return (
      <HeaderTextWrapper view={props.view}>
        <Text dangerouslySetInnerHTML={{ __html: props.text || "" }} />
        <ButtonWrapper>
          <LinkButton
            iconName={props.button.iconName}
            text={props.button.text}
            onButtonClick={props.button.onButtonClick}
          />
        </ButtonWrapper>
      </HeaderTextWrapper>
    );
  }

  return (
    <HeaderTextWrapper view={props.view}>
      <Text>{props.text}</Text>
    </HeaderTextWrapper>
  );
};

export default HeaderText;
