import React from "react";
import SVG from "react-inlinesvg";

import { ConfigContext } from "@/factory/apps/App";
import { Icons } from "@/styles/styles";

import {
  StyledNewsletterBanner,
  StyledNewsletterText,
  StyledSVGSpan,
} from "./styles";

type NewsletterBannerProps = {
  className?: string;
  dataQa: string;
  paidNewsletter?: boolean;
};

const NewsletterBanner: React.FC<NewsletterBannerProps> = (
  props: NewsletterBannerProps,
): JSX.Element => {
  const { className, dataQa, paidNewsletter } = props;
  const { iconSourceURL } = React.useContext(ConfigContext);
  return (
    <StyledNewsletterBanner className={className} data-qa={dataQa}>
      {paidNewsletter && (
        <StyledSVGSpan>
          <SVG src={`${iconSourceURL}${Icons.WeltPlus}.svg`} />
        </StyledSVGSpan>
      )}
      <StyledNewsletterText>Newsletter</StyledNewsletterText>
    </StyledNewsletterBanner>
  );
};

export default NewsletterBanner;
