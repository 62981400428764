import React from "react";

import Link from "@/components/Link";
import ShowAllButton from "@/components/ShowAllButton";
import { ContentContext } from "@/factory/apps/App";
import { Newsletter, NewsletterUser } from "@/services/Model";
import { showAllNewslettersSelector } from "@/store/application/slice";
import { useAppSelector } from "@/store/hooks";
import { newsletterSelector } from "@/store/newsletter/slice";
import { userSelector } from "@/store/user/slice";

import HeaderNewsletterCard from "../HeaderNewsletterCard/HeaderNewsletterCard";
import {
  StyledBoldSpan,
  StyledHeaderContentLoggedInWrapper,
  StyledHeaderHeadline,
  StyledHeaderParagraph,
  StyledNewsletterCardWrapper,
  StyledSubscribedNewsletterCounter,
} from "../styles";

type LoggedInHeaderProps = {
  handleNewsletterClick: (newsletterID: number, subscribe: boolean) => void;
};

const LoggedInHeader: React.FC<LoggedInHeaderProps> = (
  props: LoggedInHeaderProps,
): JSX.Element => {
  const {
    HeaderContext: {
      loggedIn: {
        title,
        intro,
        settings,
        noNewsletter,
        settingsLink,
        noNewsletterTitle,
      },
    },
  } = React.useContext(ContentContext);
  const user: NewsletterUser = useAppSelector(userSelector);
  const newsletter: Newsletter[] = useAppSelector(newsletterSelector);
  const showAllNewsletters: boolean = useAppSelector(
    showAllNewslettersSelector,
  );
  const { email } = user;

  const subscribedNewsletter: Newsletter[] = newsletter.filter(
    (newsletter) => newsletter.subscribed,
  );
  const headerTitle =
    subscribedNewsletter.length === 0 ? noNewsletterTitle : title;

  return (
    <StyledHeaderContentLoggedInWrapper data-qa="Header.LoggedIn">
      <StyledHeaderHeadline data-qa="Header.Headline">
        {headerTitle}
        <StyledSubscribedNewsletterCounter data-qa="Header.Counter">
          {subscribedNewsletter.length}
        </StyledSubscribedNewsletterCounter>
      </StyledHeaderHeadline>
      <StyledHeaderParagraph data-qa="Header.Intro">
        {intro} <br />
        <StyledBoldSpan>{email}</StyledBoldSpan>
      </StyledHeaderParagraph>
      <StyledHeaderParagraph>
        {settings}{" "}
        <Link
          href="https://www.welt.de/meinewelt/einstellungen"
          target="_blank"
          dataQa="Header.SettingsLink"
        >
          {settingsLink}
        </Link>
        .
      </StyledHeaderParagraph>

      {subscribedNewsletter.length === 0 ? (
        <StyledHeaderParagraph data-qa="Header.NoNewsletter">
          {noNewsletter}
        </StyledHeaderParagraph>
      ) : (
        <StyledNewsletterCardWrapper
          data-qa="Header.SubscribedNewsletter"
          showAll={showAllNewsletters}
        >
          {subscribedNewsletter
            .slice(0, showAllNewsletters ? subscribedNewsletter.length : 2)
            .map((newsletter, index) => (
              <HeaderNewsletterCard
                className={showAllNewsletters && index > 1 ? "fadeIn" : ""}
                key={`newsletter-header-card-${index}`}
                newsletter={newsletter}
                handleNewsletterClick={props.handleNewsletterClick}
              />
            ))}
        </StyledNewsletterCardWrapper>
      )}
      {!showAllNewsletters && subscribedNewsletter.length > 2 && (
        <ShowAllButton />
      )}
    </StyledHeaderContentLoggedInWrapper>
  );
};

export default LoggedInHeader;
