import OtherNewsletters from "./OtherNewsletters";
import { OtherNewslettersCardStyleProps } from "./OtherNewslettersCard";
import {
  OtherNewsletterSectionProps,
  OtherNewslettersSectionHeaderWrapperProps,
} from "./styles";

export type OtherNewslettersStyleProps = {
  Section: OtherNewsletterSectionProps;
  Header: OtherNewslettersSectionHeaderWrapperProps;
  Card: OtherNewslettersCardStyleProps;
};

export default OtherNewsletters;
