import { ApiResponse, isOk } from "@weltn24/la-frontend-utils";
import { getContext, put, takeLatest } from "redux-saga/effects";

import { IPaperboyApi } from "@/services/api/PaperboyApi";
import { NewsletterUser } from "@/services/Model";

import { fetchUser, fetchUserError, fetchUserSuccess } from "./slice";

export function* getUser() {
  try {
    const paperboyApi: IPaperboyApi = yield getContext("paperboyApi");
    const request: Promise<ApiResponse<NewsletterUser>> = yield paperboyApi
      .user()
      .then(isOk);

    yield put(fetchUserSuccess(request));
  } catch (error) {
    yield put(fetchUserError());
  }
}

export function* watchFetchUser() {
  yield takeLatest(fetchUser, getUser);
}
