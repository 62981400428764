import styled from "styled-components";

export const StyledFeaturedWrapper = styled.div`
  height: 524px;

  display: flex;
  align-items: center;
  flex-direction: column;
  position: relative;

  width: unset;
  margin: 0 -16px;

  /* Overwrite specific Swiper.js CSS classes */
  .swiper {
    width: 100%;
    height: 100%;
    max-height: 653px;
    max-width: 910px;
  }

  .swiper-slide {
    width: 85%;
    display: flex;
    justify-content: center;
    margin: 0 5px;
  }

  .swiper-fade .swiper-slide.swiper-slide-visible.swiper-slide-active {
    opacity: 1 !important;
  }

  /* place the card swiper on top of the image slider */
  .featured-newsletter-card-swiper {
    position: absolute;
    top: 0;
  }

  .featured-newsletter-card-swiper .swiper-slide {
    top: 145px;
  }

  /* Bottom Pagination Navigation Points */
  .swiper-pagination-bullet {
    background-color: #e5e5e5;
    opacity: 1;
    margin: 0 12px !important;
  }

  .swiper-pagination-bullet-active {
    background-color: #f18825;
  }

  .swiper-pagination {
    height: 30px;
  }

  .swiper-button-prev,
  .swiper-button-next {
    display: none;
  }

  /* Adjust for desktop devices */
  @media (min-width: 945px) {
    height: 640px;

    .swiper-slide {
      width: 70%;
      display: flex;
      justify-content: center;
      margin: 0;
    }

    .swiper-pagination {
      height: 26px;
    }

    .featured-newsletter-card-swiper .swiper-slide {
      top: 287px;
    }

    .swiper-pagination-bullet {
      margin: 0 5px !important;
    }

    /* Arrow Navigation Buttons */
    .swiper-button-prev,
    .swiper-button-next {
      display: block;
    }

    .swiper-button-next::after,
    .swiper-button-prev::after {
      position: absolute;
      width: 60px;
      height: 60px;
      top: -160px;
      left: 7px;
      background: #ffffff;
      color: black;
      opacity: 0.8;
      box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
      font-size: 20px;
      border-radius: 50%;
      padding: 20px 10px 20px 0px;

      transition: color 0.15s ease-in-out;
    }

    .swiper-button-prev::after {
      left: -40px;
      padding: 20px 0 20px 10px;
    }

    .swiper-button-next:hover::after,
    .swiper-button-prev:hover::after {
      color: #f18825;
      transition: color 0.15s ease-in-out;
    }
  }
`;

type StyledFeaturedBackgroundImageProps = {
  src: string;
};
export const StyledFeaturedBackgroundImage = styled.div<StyledFeaturedBackgroundImageProps>`
  background-image: url(${(props) => props.src});
  background-repeat: no-repeat;
  background-size: cover;
  background-position-x: right;
  background-position-y: top;

  position: absolute;
  top: 0;

  width: 100%;
  height: 180px;
  border-radius: 3px;

  @media ${(props) => props.theme.global.breakpoints.desktop} {
    width: 910px;
    height: 337px;
  }
`;
