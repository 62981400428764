import React, { useEffect, useRef } from "react";
import SVG from "react-inlinesvg";

import LoaderUntil from "@/components/LoaderUntil";
import LoginDialog from "@/components/LoginDialog";
import TopButton from "@/components/TopButton";
import { ConfigContext, ContentContext } from "@/factory/apps/App";
import FeaturedNewsletter from "@/sections/FeaturedNewsletter";
import Header from "@/sections/Header";
import Navigation from "@/sections/Navigation";
import TopicNewsletter from "@/sections/TopicNewsletter";
import { NewsletterUser } from "@/services/Model";
import { showDialog } from "@/store/application/slice";
import { useAppDispatch, useAppSelector } from "@/store/hooks";
import {
  fetchingNewslettersSelector,
  fetchNewsletters,
  subscribeNewsletter,
  unSubscribeNewsletter,
} from "@/store/newsletter/slice";
import { fetchUser } from "@/store/user/slice";
import { userSelector } from "@/store/user/slice";
import { Icons } from "@/styles/styles";

const Registration: React.FC = (): JSX.Element => {
  const dispatch = useAppDispatch();
  const { iconSourceURL } = React.useContext(ConfigContext);
  const { LoadingContext } = React.useContext(ContentContext);
  const user: NewsletterUser = useAppSelector(userSelector);
  const pageTopRef = useRef<HTMLElement>(null);

  useEffect(() => {
    dispatch(fetchNewsletters());
    dispatch(fetchUser());
  }, []);

  const handleNewsletterClick = (
    newsletterID: number,
    subscribe: boolean,
  ): void => {
    if (!user.isLoggedIn) {
      dispatch(showDialog());
      return;
    }

    if (subscribe) {
      dispatch(subscribeNewsletter({ newsletterID }));
      return;
    }
    dispatch(unSubscribeNewsletter({ newsletterID }));
  };

  const isFetchingNewsletters: boolean = useAppSelector(
    fetchingNewslettersSelector,
  );

  return (
    <span ref={pageTopRef}>
      <LoginDialog />
      <Header handleNewsletterClick={handleNewsletterClick} />
      <Navigation />
      <LoaderUntil
        condition={!isFetchingNewsletters}
        loadingDescription={LoadingContext.newsletter}
      >
        <>
          <FeaturedNewsletter handleNewsletterClick={handleNewsletterClick} />
          <TopicNewsletter handleNewsletterClick={handleNewsletterClick} />
        </>
      </LoaderUntil>
      <TopButton target={pageTopRef}>
        <SVG src={`${iconSourceURL}${Icons.ArrowUp}.svg`} />
      </TopButton>
    </span>
  );
};

export default Registration;
