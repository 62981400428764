import styled from "styled-components";

export const StyledContainer = styled.div`
  position: fixed;
  z-index: 999999;
  right: 0;
  bottom: 0;
  left: 0;
  height: auto;
  background-color: gold;

  dl {
    display: table;
    min-width: 400px;
    margin: 0 auto;
  }

  dt,
  dd {
    padding: 2px 5px;
  }

  dt {
    display: table-cell;
    font-weight: 600;
    text-align: right;
    white-space: nowrap;
  }

  dd {
    display: table-cell;
    background: silver;
  }

  pre {
    padding: 0 5px;
    font-family: consolas, "Liberation Mono", courier, monospace;
  }

  .flex {
    display: flex;
  }

  .button {
    padding: 3px;
    border: 1px solid black;
    border-radius: 3px;
  }
`;
