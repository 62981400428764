import styled from "styled-components";

export const StyledTopicNewsletterContainer = styled.section`
  max-width: 910px;
  margin: 0 auto 10px;
`;

export const StyledTopicNewsletterSection = styled.div`
  border-top: 1px solid ${(props) => props.theme.color.border};
  margin: 0 calc(-2 * ${(props) => props.theme.margin.m});
  padding: 0 calc(2 * ${(props) => props.theme.padding.m});

  @media ${(props) => props.theme.global.breakpoints.desktop} {
    padding: 0 ${(props) => props.theme.padding.m}
      calc(2 * ${(props) => props.theme.padding.xl})
      ${(props) => props.theme.padding.m};
  }
`;

export const StyledWrapper = styled.div`
  display: flex;
  flex-basis: content;
  flex-wrap: wrap;
  justify-content: center;
  column-gap: ${(props) => props.theme.gap.l};
  row-gap: ${(props) => props.theme.gap.m};

  @media (min-width: 909px) {
    justify-content: unset;
    row-gap: ${(props) => props.theme.gap.l};
    padding: 0 ${(props) => props.theme.margin.m};
  }
`;
