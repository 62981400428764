import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import { RootState } from "../";

export type UserState = {
  authToken: string;
  email: string;
  emailIsInvalid: boolean;
  loggedIn: boolean;
  advertisementOptIn: boolean;
  isLoadingUser: boolean;
  isErrorUser: boolean;
};

/* initial state */
const initialState: UserState = {
  authToken: "",
  email: "",
  emailIsInvalid: false,
  loggedIn: false,
  advertisementOptIn: false,
  isLoadingUser: false,
  isErrorUser: false,
};

/* reducer */
const slice = createSlice({
  name: "user",
  initialState,
  reducers: {
    setEmail: (
      state,
      action: PayloadAction<{
        email: string;
      }>,
    ) => {
      const checkIfEmailIsInvalid = (email: string): boolean => {
        return !/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email);
      };
      state.email = action.payload.email;
      state.emailIsInvalid = checkIfEmailIsInvalid(action.payload.email);
    },
    setMediaOptIn: (
      state,
      action: PayloadAction<{
        advertisementOptIn: boolean;
      }>,
    ) => {
      state.advertisementOptIn = action.payload.advertisementOptIn;
    },
    /*eslint-disable-next-line @typescript-eslint/no-unused-vars*/
    fetchUserinfoInit: (state, action) => {
      state.isLoadingUser = true;
      state.isErrorUser = false;
    },
    fetchUserinfoFailure: (state) => {
      state.isLoadingUser = false;
      state.isErrorUser = true;
    },
    fetchUserinfoSuccess: (
      state,
      action: PayloadAction<{
        authToken: string;
        email: string;
        advertisementOptIn: boolean;
        loggedIn: boolean;
      }>,
    ) => {
      state.authToken = action.payload.authToken;
      state.isLoadingUser = false;
      state.isErrorUser = false;
      state.email = action.payload.email || "";
      state.advertisementOptIn = action.payload.advertisementOptIn;
      state.loggedIn = action.payload.loggedIn;
    },
  },
});

export const userSliceSelector = (state: RootState): UserState => state.user;

const { actions, reducer } = slice;

// Actions
export const {
  setEmail,
  setMediaOptIn,
  fetchUserinfoSuccess,
  fetchUserinfoInit,
  fetchUserinfoFailure,
} = actions;

export default reducer;
