import React from "react";
import { useLocation } from "react-router-dom";

import {
  ConfigContext,
  NoSSOGlobalContext,
  Views,
} from "@/factory/apps/NoSSOApp";
import Header from "@/noSSOPartials/Header";
import SelectedNewsletters from "@/noSSOPartials/SelectedNewsletters";
import { Newsletter } from "@/services/Model";

interface State {
  newsletters: Newsletter[];
}

const Confirmation: React.FC = (): JSX.Element => {
  const location = useLocation<State>();
  const { ConfirmationTexts } = React.useContext(NoSSOGlobalContext);
  const { homePageURL } = React.useContext(ConfigContext);

  return (
    <React.Fragment>
      <Header
        subtitle={ConfirmationTexts.subtitle}
        title={ConfirmationTexts.title}
        text={ConfirmationTexts.text}
        view={Views.Confirmation}
        button={{
          iconName: ConfirmationTexts.buttonIconName,
          text: ConfirmationTexts.buttonText,
          onButtonClick: () => window.open(homePageURL, "_self"),
        }}
      />
      <SelectedNewsletters newsletters={location.state.newsletters} />
    </React.Fragment>
  );
};

export default Confirmation;
