import Header from "./Header";
import {
  DescriptionHeadlineProps,
  DescriptionListProps,
  DescriptionProps,
  HeaderBackgroundProps,
  HeaderHeadlineProps,
  HeaderTextWrapperProps,
  HeadlineProps,
  SubtitleProps,
  TextProps,
} from "./styles";

export type HeaderStyleProps = {
  HeaderBackground: HeaderBackgroundProps;
  HeaderHeadline: HeaderHeadlineProps;
  TextWrapper: HeaderTextWrapperProps;
  Description?: DescriptionProps;
  DescriptionList?: DescriptionListProps;
  DescriptionHeadline?: DescriptionHeadlineProps;
  Headline: HeadlineProps;
  Subtitle: SubtitleProps;
  TextProps: TextProps;
};

export default Header;
