import styled from "styled-components";

import { expandAnimation } from "@/styles/common";

type StyledNewsletterCardWrapperProps = {
  showAll: boolean;
};

export const StyledHeader = styled.div`
  background-color: ${(props) => props.theme.color.primary_brand_color};
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  min-height: 207px;
  margin: 0 -15px;

  &.loggedIn {
    background: linear-gradient(9.82deg, #165b8d 32.18%, #003a5a 79.21%);
  }

  @media ${(props) => props.theme.global.breakpoints.desktop} {
    margin: 0 -30px;
  }
`;

export const StyledHeaderContentLoggedInWrapper = styled.div`
  padding: ${(props) => props.theme.padding.m};

  @media ${(props) => props.theme.global.breakpoints.desktop} {
    width: 970px;
  }
`;

export const StyledHeaderContentWrapper = styled.div`
  max-width: 420px;
  padding: 0 ${(props) => props.theme.padding.m};

  @media ${(props) => props.theme.global.breakpoints.desktop} {
    padding: 0;
  }
`;

export const StyledHeaderHeadline = styled.h3`
  color: ${(props) => props.theme.color.white};
  font-family: ${(props) => props.theme.fontFamily.heavy};
  font-size: ${(props) => props.theme.fontSize.l};
  line-height: ${(props) => props.theme.lineHeight.l};
  text-transform: uppercase;
`;

export const StyledHeaderParagraph = styled.p`
  color: ${(props) => props.theme.color.white};
  font-family: ${(props) => props.theme.fontFamily.regular};
  font-size: ${(props) => props.theme.fontSize.m};
  line-height: ${(props) => props.theme.lineHeight.l};
  margin: ${(props) => props.theme.margin.m} 0;
`;

export const StyledBoldSpan = styled.span`
  font-family: ${(props) => props.theme.fontFamily.heavy};
`;

export const StyledNewsletterCardWrapper = styled.div<StyledNewsletterCardWrapperProps>`
  animation: ${(props) => props.showAll && expandAnimation(280)} 0.5s
    ease-in-out;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;

  gap: ${(props) => props.theme.padding.s};

  @media ${(props) => props.theme.global.breakpoints.desktop} {
    animation: ${(props) => props.showAll && expandAnimation(160)} 0.5s
      ease-in-out;
  }
`;

export const StyledSubscribedNewsletterCounter = styled.span`
  display: inline-block;
  background-color: ${(props) => props.theme.color.highlight};
  color: ${(props) => props.theme.color.white};
  font-size: ${(props) => props.theme.fontSize.s};
  line-height: ${(props) => props.theme.lineHeight.l};
  text-align: center;
  width: 20px;
  height: 20px;
  border-radius: 10px;
  vertical-align: bottom;
  margin-left: 4px;
`;
