import { all, setContext } from "redux-saga/effects";

import { IPaperboyApi } from "@/services/api/PaperboyApi";

import {
  watchFetchNewsletters,
  watchSubscribeNewsletter,
  watchUnSubscribeNewsletter,
} from "./newsletter/sagas";
import { watchFetchUser } from "./user/sagas";

/** start all sagas */
export default function* rootSaga(p: IPaperboyApi): Generator {
  yield setContext({ paperboyApi: p });
  yield all([
    watchFetchNewsletters(),
    watchSubscribeNewsletter(),
    watchUnSubscribeNewsletter(),
    watchFetchUser(),
  ]);
}
