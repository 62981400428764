import { AbortableFetch, Api, ApiResponse } from "@weltn24/la-frontend-utils";

import { getBrandIdentifier } from "@/Brands";
import {
  BatchSubscriptionRequest,
  Newsletter,
  UserInfo,
} from "@/services/Model";
import { getDocumentUrl } from "@/utils/document";

export interface INoSSOPaperboyApi {
  batchSubscription(
    requestBody: BatchSubscriptionRequest,
    authToken?: string,
  ): Promise<ApiResponse<void>>;
  getNewsletters(authToken?: string): AbortableFetch<Newsletter[]>;
  getUserInfo(authToken: string): AbortableFetch<UserInfo>;
}
const brandIdentifier = getBrandIdentifier();
const newslettersRequestURL = `/${brandIdentifier}/newsletters`;
const batchSubscriptionURL = `/${brandIdentifier}/newsletters/subscription-batch`;

export default (api: Api): INoSSOPaperboyApi => {
  const getNewsletters = (authToken?: string): AbortableFetch<Newsletter[]> => {
    if (authToken) {
      return api.get(`${newslettersRequestURL}?tp=${authToken}`, true, {
        "X-Referrer": getDocumentUrl(),
        "X-Client": "la-paperboy-web",
      });
    }

    return api.get(newslettersRequestURL, true, {
      "X-Referrer": getDocumentUrl(),
      "X-Client": "la-paperboy-web",
    });
  };

  const batchSubscription = (
    requestBody: BatchSubscriptionRequest,
    authToken: string,
  ): Promise<ApiResponse<void>> => {
    if (authToken) {
      return api.post(`${batchSubscriptionURL}?tp=${authToken}`, requestBody, {
        "X-Referrer": getDocumentUrl(),
        "X-Client": "la-paperboy-web",
      });
    }

    return api.post(batchSubscriptionURL, requestBody, {
      "X-Referrer": getDocumentUrl(),
      "X-Client": "la-paperboy-web",
    });
  };

  const getUserInfo = (authToken: string): AbortableFetch<UserInfo> => {
    return api.get(`/${brandIdentifier}/user?tp=${authToken}`, false, {
      "X-Referrer": getDocumentUrl(),
      "X-Client": "la-paperboy-web",
    });
  };

  return {
    batchSubscription,
    getNewsletters,
    getUserInfo,
  };
};
