import styled from "styled-components";

import { Colors, FontFamily } from "@/styles/styles";

export type BasicButtonProps = {
  borderWidth: string;
  borderStyle: string;
  borderColor: Colors;
  borderRadius: string;
  width: string;
  height: string;
  backgroundColor: Colors;
  color: Colors;
  fontSize: string;
  fontFamily: FontFamily;
  fontWeight: number;
  letterSpacing: string;
  desktop: {
    height: string;
    fontSize?: string;
  };
  mobileSmall?: {
    height?: string;
  };
  hover: {
    backgroundColor: Colors;
    color: Colors;
    borderColor: Colors;
  };

  subscribed: {
    backgroundColor: Colors;
    borderColor: Colors;
    color: Colors;
  };
  checkmark: {
    color: Colors;
  };
};

export const BasicButton = styled.button<{ subscribed?: boolean }>`
  display: inline-block;
  width: ${(props) => props.theme.components.Buttons.Basic.width};
  height: ${(props) => props.theme.components.Buttons.Basic.height};
  align-items: center;
  justify-content: center;
  border-width: ${(props) => props.theme.components.Buttons.Basic.borderWidth};
  border-style: ${(props) => props.theme.components.Buttons.Basic.borderStyle};
  border-color: ${(props) =>
    props.subscribed
      ? props.theme.components.Buttons.Basic.subscribed.borderColor
      : props.theme.components.Buttons.Basic.borderColor};
  background-color: ${(props) =>
    props.subscribed
      ? props.theme.components.Buttons.Basic.subscribed.backgroundColor
      : props.theme.components.Buttons.Basic.backgroundColor};

  border-radius: ${(props) =>
    props.theme.components.Buttons.Basic.borderRadius};
  color: ${(props) =>
    props.subscribed
      ? props.theme.components.Buttons.Basic.subscribed.color
      : props.theme.components.Buttons.Basic.color};
  font-family: ${(props) => props.theme.components.Buttons.Basic.fontFamily};
  font-size: ${(props) => props.theme.components.Buttons.Basic.fontSize};
  font-weight: ${(props) => props.theme.components.Buttons.Basic.fontWeight};
  letter-spacing: ${(props) =>
    props.theme.components.Buttons.Basic.letterSpacing};
  text-align: center;
  text-transform: uppercase;
  transition-duration: ${(props) => props.theme.global.transitions.duration};
  transition-property: background-color, color, border;
  transition-timing-function: ${(props) =>
    props.theme.global.transitions.speedCurve};
  @media ${(props) => props.theme.global.breakpoints.desktop} {
    height: ${(props) => props.theme.components.Buttons.Basic.desktop.height};
    font-size: ${(props) =>
      props.theme.components.Buttons.Basic.desktop?.fontSize};
    &:hover {
      border-color: ${(props) =>
        props.theme.components.Buttons.Basic.hover.borderColor};
      background-color: ${(props) =>
        props.theme.components.Buttons.Basic.hover.backgroundColor};
      color: ${(props) => props.theme.components.Buttons.Basic.hover.color};
      cursor: pointer;
    }
  }
  @media ${(props) => props.theme.global.breakpoints.mobileSmall} {
    height: ${(props) =>
      props.theme.components.Buttons.Basic.mobileSmall?.height};
  }

  &:focus {
    outline: 0;
  }

  &:active {
    outline: none;
  }
`;
export const StyledWrapper = styled.div<{ subscribed?: boolean }>`
  display: inline-block;
`;

export const StyledCheckmark = styled.div<{ subscribed?: boolean }>`
  position: absolute;
  margin-left: -13px;
  visibility: ${(props) => (props.subscribed ? "visible" : "hidden")};
  svg {
    display: inline-block;
    fill: ${(props) => props.theme.components.Buttons.Basic.checkmark.color};
    transition-duration: ${(props) => props.theme.global.transitions.duration};
    transition-property: fill;
    transition-timing-function: ${(props) =>
      props.theme.global.transitions.speedCurve};
    vertical-align: baseline;
    @media ${(props) => props.theme.global.breakpoints.desktop} {
      ${BasicButton}:hover & {
        fill: ${(props) => props.theme.components.Buttons.Basic.hover.color};
      }
    }
  }
`;

export const ButtonText = styled.div<{ subscribed?: boolean }>`
  transform: ${(props) => (props.subscribed ? "translateX(13px);" : "none")};
  transition: transform 300ms ease;
`;
