import { WhoamiWrapper } from "@weltn24/la-frontend-utils";
import React from "react";

import Link from "@/components/Link";
import StyledButton from "@/components/StyledButton";
import { ContentContext } from "@/factory/apps/App";

import {
  StyledHeaderContentWrapper,
  StyledHeaderHeadline,
  StyledHeaderParagraph,
} from "../styles";

const LoggedOutHeader: React.FC = (): JSX.Element => {
  const whoami = WhoamiWrapper.getInstance();
  const doLogin = () => whoami.doLogin();
  const doRegister = () => whoami.doRegister();

  const {
    HeaderContext: {
      loggedOut: { title, register, login, intro },
    },
  } = React.useContext(ContentContext);

  return (
    <StyledHeaderContentWrapper>
      <StyledHeaderHeadline> {title} </StyledHeaderHeadline>
      <StyledHeaderParagraph>
        {intro}{" "}
        <Link className="underlined" onClick={() => doRegister()}>
          {" "}
          {register}
        </Link>
      </StyledHeaderParagraph>
      <StyledButton onClick={() => doLogin()} className="inverted">
        {login}
      </StyledButton>
    </StyledHeaderContentWrapper>
  );
};

export default LoggedOutHeader;
