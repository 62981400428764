import { StorageService } from "@weltn24/la-frontend-utils";

import { log } from "@/utils/logger";

import { isProduction, isStaging } from "./services/Environment";

const storageService: StorageService = StorageService.instance();
export const apiCookieName = "bildpaperboy";

export interface IConfig {
  baseApi: string;
  baseApiNoCdn: string;
  baseApiPath: string;
  version: string;
}

const apiConfig: IConfig = {
  baseApi: isProduction()
    ? "https://production-nocdn.la.welt.de"
    : "https://staging-nocdn.la.welt.de",
  baseApiNoCdn: "https://production-nocdn.la.welt.de",
  baseApiPath: "la-paperboy-api",
  version: "v1",
};

export function getBaseURL(): string {
  if (!isProduction() && !isStaging()) {
    return "https://local.la.welt.de:8452";
  }

  return isProduction()
    ? "https://resources-production.la.welt.de/la-paperboy-web"
    : `https://resources-staging.la.welt.de${__PUBLIC_PATH__}`;
}

export function getApiUrl(): string {
  let url: string = apiConfig.baseApi;

  if (storageService.hasCookie(apiCookieName)) {
    const host: string = storageService.getCookieValue(apiCookieName);
    log.debug(`API cookie value: ${host}`);
    if (host && host.length) {
      url = host;
      if (!url.startsWith("http")) {
        url = "https://" + url;
      }
    }
    log.debug(`API URL: ${url}`);
  }

  return `${url}/${apiConfig.baseApiPath}/${apiConfig.version}`;
}

export default apiConfig;
