import React from "react";

import {
  weltTopicHeadlineMapper,
  weltTopicOrder,
  WeltTopics,
} from "@/brandConfig/configs";
import Headline from "@/components/Headline";
import { Newsletter } from "@/services/Model";
import { useAppSelector } from "@/store/hooks";
import { newsletterSelector } from "@/store/newsletter/slice";

import {
  StyledTopicNewsletterContainer,
  StyledTopicNewsletterSection,
  StyledWrapper,
} from "./styles";
import TopicNewsletterCard from "./TopicNewsletterCard";

export type TopicNewsletterProps = {
  handleNewsletterClick: (newsletterID: number, subscribe: boolean) => void;
};

const TopicNewsletter: React.FC<TopicNewsletterProps> = (
  props: TopicNewsletterProps,
): JSX.Element => {
  const { handleNewsletterClick } = props;
  const newsletters: Newsletter[] = useAppSelector(newsletterSelector);

  return (
    <StyledTopicNewsletterContainer>
      {weltTopicOrder.map((topic: WeltTopics, index: number) => {
        return (
          <StyledTopicNewsletterSection
            key={`section-topic-newsletter-${index}`}
            id={`${topic}-wrapper`}
          >
            <Headline
              key={`section-topic-newsletter-headline-${index}`}
              dataQa="TopicNewsletter.Headline"
            >
              {weltTopicHeadlineMapper[topic]}
            </Headline>
            <StyledWrapper>
              {newsletters.map((newsletter: Newsletter, index: number) => {
                if (newsletter.categories.indexOf(topic) !== -1) {
                  return (
                    <TopicNewsletterCard
                      newsletter={newsletter}
                      handleNewsletterClick={handleNewsletterClick}
                      key={`topic-newsletter-card-${index}`}
                    />
                  );
                }
              })}
            </StyledWrapper>
          </StyledTopicNewsletterSection>
        );
      })}
    </StyledTopicNewsletterContainer>
  );
};

export default TopicNewsletter;
