import React from "react";

import CheckBox from "@/components/CheckBox";
import FinePrint from "@/components/FinePrint";
import InputField from "@/components/InputField";
import SectionErrorUI from "@/components/SectionErrorUI";
import Spinner from "@/components/Spinner";
import Button from "@/components/SubmitButton";
import { Umlauts } from "@/content/characterUnicodes";
import { ConfigContext, NoSSOGlobalContext } from "@/factory/apps/NoSSOApp";
import { useAppDispatch, useAppSelector } from "@/store/noSSO/hooks";
import { newsletterSliceSelector } from "@/store/noSSO/newsletter/slice";
import { setEmail, userSliceSelector } from "@/store/noSSO/user/slice";
import { Icons } from "@/styles/styles";

import {
  ButtonInnerWrapper,
  StyledBox,
  StyledBoxWrapper,
  StyledHeadline,
  StyledLink,
  StyledOptInWrapper,
  StyledSubmitWrapper,
} from "./styles";

export type RegistrationBoxTexts = {
  headline: string;
  buttonLabel: string;
  optInLabel: string;
  paragraph: string;
};

export type RegistrationBoxProps = {
  onSubmit: (email: string) => void;
  setAdvertisementOptIn: (advertisementOptIn: boolean) => void;
  errorMessage?: string;
};

const RegistrationBox: React.FC<RegistrationBoxProps> = (
  props: RegistrationBoxProps,
): JSX.Element => {
  const { onSubmit, setAdvertisementOptIn } = props;

  const userStore = useAppSelector(userSliceSelector);
  const newsletterStore = useAppSelector(newsletterSliceSelector);

  const { dataProtectionNoticeURL, dataProtectionURL, hasOptInLinks } =
    React.useContext(ConfigContext);
  const { FinePrintTexts } = React.useContext(NoSSOGlobalContext);
  const dispatch = useAppDispatch();

  const disabledButton =
    userStore.email.length === 0 ||
    userStore.emailIsInvalid ||
    newsletterStore.subscriptions.length === 0;

  if (newsletterStore.submittingFailed) {
    return <SectionErrorUI />;
  }

  const handleChange = (value: string) => {
    dispatch(setEmail({ email: value }));
  };
  return (
    <StyledBoxWrapper>
      <StyledBox data-qa="RegistrationBox">
        <StyledHeadline>
          {userStore.loggedIn
            ? `Willkommen zur${Umlauts.ue}ck!`
            : "Bitte geben Sie Ihre E-Mail-Adresse ein"}
        </StyledHeadline>
        <StyledSubmitWrapper>
          <InputField
            email={userStore.email}
            disabled={newsletterStore.submitted || userStore.loggedIn}
            error={userStore.emailIsInvalid}
            active={userStore.loggedIn && userStore.email.length > 0}
            errorMessage={"Die E-Mail-Adresse ist invalide"}
            onChange={handleChange}
          />
          <Button
            disabled={disabledButton}
            onClick={() => onSubmit(userStore.email)}
          >
            <ButtonInnerWrapper>
              {newsletterStore.submitting && <Spinner name={Icons.Spinner} />}
              {userStore.loggedIn
                ? `${Umlauts.Ae}nderungen ${Umlauts.ue}bernehmen`
                : "Kostenlos abonnieren"}
            </ButtonInnerWrapper>
          </Button>
        </StyledSubmitWrapper>
        <StyledOptInWrapper>
          <CheckBox
            isChecked={userStore.advertisementOptIn}
            onChange={(checked: boolean) => setAdvertisementOptIn(checked)}
          />
          <FinePrint>
            <span>
              {FinePrintTexts.data}
              {hasOptInLinks == true && (
                <span>
                  <StyledLink
                    href={dataProtectionNoticeURL}
                    rel="no-follow noopener noreferrer"
                    target="_blank"
                  >
                    pers{Umlauts.oe}nlichen Nutzung{" "}
                  </StyledLink>
                  zuschickt. Meine Einwilligung kann ich jederzeit{" "}
                  <StyledLink
                    href={dataProtectionURL}
                    rel="no-follow noopener noreferrer"
                    target="_blank"
                  >
                    widerrufen
                  </StyledLink>
                  .
                </span>
              )}
            </span>
          </FinePrint>
        </StyledOptInWrapper>
        <FinePrint>{FinePrintTexts.optIn}</FinePrint>
      </StyledBox>
    </StyledBoxWrapper>
  );
};

export default RegistrationBox;
