import styled from "styled-components";

import { blockCenterRelative } from "@/styles/common";
import { Colors } from "@/styles/styles";

export type StyledBoxWrapperProps = {
  backgroundColor: Colors;
  padding?: string;
  desktop?: {
    padding?: string;
  };
};

export const StyledBoxWrapper = styled.section`
  padding: ${(props) => props.theme.registrationBox.Wrapper?.padding};
  background: ${(props) => props.theme.registrationBox.Wrapper.backgroundColor};
  @media ${(props) => props.theme.global.breakpoints.desktop} {
    padding: ${(props) => props.theme.registrationBox.Wrapper.desktop?.padding};
  }
`;

export type StyledBoxProps = {
  backgroundColor: Colors;
  borderWidth: string;
  borderColor: string;
  borderRadius: string;
};

export const StyledBox = styled.section`
  ${blockCenterRelative}
  max-width:${(props) => props.theme.mainContent.maxWidth};
  box-sizing: border-box;
  padding: 25px 10px;
  border: ${(props) => props.theme.registrationBox.Box.borderWidth} solid
    ${(props) => props.theme.registrationBox.Box.borderColor};
  margin: 0 12px;
  background: ${(props) => props.theme.registrationBox.Box.backgroundColor};
  border-radius: ${(props) => props.theme.registrationBox.Box.borderRadius};
  @media ${(props) => props.theme.global.breakpoints.desktop} {
    padding: 25px 15px;
    margin: 0 auto;
  }
`;

export type StyledHeadlineProps = {
  fontFamily: string;
  fontSize: string;
  fontWeight: number;
  desktop: {
    fontSize: string;
  };
};

export const StyledHeadline = styled.div`
  margin-bottom: 30px;
  font-family: ${(props) => props.theme.registrationBox.Headline.fontFamily};
  font-size: ${(props) => props.theme.registrationBox.Headline.fontSize};
  font-weight: ${(props) => props.theme.registrationBox.Headline.fontWeight};
  @media ${(props) => props.theme.global.breakpoints.desktop} {
    font-size: ${(props) =>
      props.theme.registrationBox.Headline.desktop.fontSize};
  }
`;

export const StyledSubmitWrapper = styled.div`
  @media ${(props) => props.theme.global.breakpoints.desktop} {
    display: flex;

    & > :last-child {
      max-width: 224px;
      margin-left: 16px;
    }
  }
`;

export type StyledOptInWrapperProps = {
  padding: string;
  desktop: {
    padding: string;
  };
};

export const StyledOptInWrapper = styled.div`
  display: flex;
  padding: ${(props) => props.theme.registrationBox.OptIn.padding};

  & > :first-child {
    flex-shrink: 0;
    margin-right: 8px;
  }
  @media ${(props) => props.theme.global.breakpoints.desktop} {
    padding: ${(props) => props.theme.registrationBox.OptIn.desktop.padding};
  }
`;

export const ButtonInnerWrapper = styled.span`
  display: flex;
  align-items: center;
  justify-content: center;

  svg {
    display: block;
    margin-right: 5px;
  }
`;

export const StyledLink = styled.a`
  color: ${(props) => props.theme.global.colors.black};
  text-decoration: underline;

  &:visited,
  &:active,
  &:hover,
  &:link {
    color: ${(props) => props.theme.global.colors.black};
  }
`;
