import React from "react";

import SectionHeader from "@/components/SectionHeader";
import { Newsletter, Subscription } from "@/services/Model";

import OtherNewsletterCard from "./OtherNewslettersCard";
import {
  OtherNewsletterList,
  OtherNewsletterListWrapper,
  OtherNewsletterSection,
  OtherNewslettersSectionHeaderWrapper,
} from "./styles";

export type OtherNewsletterProps = {
  sectionHeader: string;
  newsletters: Newsletter[];
  handleNewsletterClick: (selectedNewsletter: Subscription) => void;
};

const OtherNewsletters: React.FC<OtherNewsletterProps> = (
  props: OtherNewsletterProps,
): JSX.Element => {
  const { sectionHeader, newsletters, handleNewsletterClick } = props;

  return (
    <OtherNewsletterSection>
      <OtherNewslettersSectionHeaderWrapper>
        <SectionHeader>{sectionHeader}</SectionHeader>
      </OtherNewslettersSectionHeaderWrapper>
      <OtherNewsletterListWrapper>
        <OtherNewsletterList>
          {newsletters.map((newsletter: Newsletter) => (
            <OtherNewsletterCard
              {...newsletter}
              key={`otherNewsletters-${newsletter.newsletterID}`}
              handleNewsletterClick={handleNewsletterClick}
            />
          ))}
        </OtherNewsletterList>
      </OtherNewsletterListWrapper>
    </OtherNewsletterSection>
  );
};

export default OtherNewsletters;
