import {
  sendTrackingToTealium,
  TealiumTrackingData,
} from "@weltn24/la-frontend-utils";

enum TrackingAction {
  Subscribe = "subscribe",
  UnSubscribe = "unsubscribe",
  Render = "rendered",
}

const retryCount = 5;

const track = (eventName: string, eventAction: TrackingAction): void => {
  const trackingData: TealiumTrackingData = {
    event_name: eventName,
    event_action: eventAction,
  };

  sendTrackingToTealium(trackingData, retryCount);
};

export const trackSubscribe = (newsletterID: number): void => {
  track(`la_paperboy_web_${newsletterID}`, TrackingAction.Subscribe);
};

export const trackUnsubscribe = (newsletterID: number): void => {
  track(`la_paperboy_web_${newsletterID}`, TrackingAction.UnSubscribe);
};

export const trackRender = (): void => {
  track("la_paperboy_web_render", TrackingAction.Render);
};
