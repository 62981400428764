import React from "react";

import LoaderUntil from "@/components/LoaderUntil";
import { ContentContext } from "@/factory/apps/App";
import { NewsletterUser } from "@/services/Model";
import { useAppSelector } from "@/store/hooks";
import { fetchUserSelector, userSelector } from "@/store/user/slice";

import LoggedInHeader from "./LoggedInHeader";
import LoggedOutHeader from "./LoggedOutHeader";
import { StyledHeader } from "./styles";

type HeaderProps = {
  handleNewsletterClick: (newsletterID: number, subscribe: boolean) => void;
};

const Header: React.FC<HeaderProps> = (props: HeaderProps): JSX.Element => {
  const { LoadingContext } = React.useContext(ContentContext);
  const userIsFetching = useAppSelector(fetchUserSelector);
  const user: NewsletterUser = useAppSelector(userSelector);
  const { isLoggedIn } = user;

  return (
    <StyledHeader className={isLoggedIn ? "loggedIn" : ""}>
      <LoaderUntil
        condition={!userIsFetching}
        loadingDescription={LoadingContext.user}
        className="inverted"
      >
        {isLoggedIn ? (
          <LoggedInHeader handleNewsletterClick={props.handleNewsletterClick} />
        ) : (
          <LoggedOutHeader />
        )}
      </LoaderUntil>
    </StyledHeader>
  );
};

export default Header;
