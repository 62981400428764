import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import { Newsletter, Subscription } from "@/services/Model";

import { RootState } from "../";

export type NewsletterState = {
  newsletters: Newsletter[];
  subscriptions: Subscription[];
  isLoadingNewsletter: boolean;
  isErrorNewsletter: boolean;
  submitting: boolean;
  submittingFailed: boolean;
  submitted: boolean;
};

/* initial state */
const initialState: NewsletterState = {
  newsletters: [],
  subscriptions: [],
  isLoadingNewsletter: false,
  isErrorNewsletter: false,
  submitting: false,
  submittingFailed: false,
  submitted: false,
};

const updateSpecificStateObjectBasedOnId = (
  referenceElement: Newsletter | Subscription,
  stateObject: Newsletter[] | Subscription[],
): Newsletter[] | Subscription[] => {
  const index = stateObject.findIndex(
    (element: Newsletter | Subscription) =>
      element.newsletterID === referenceElement.newsletterID,
  );

  stateObject[index] = {
    ...stateObject[index],
    subscribed: referenceElement.subscribed,
  };

  return stateObject;
};

/* reducer */
const slice = createSlice({
  name: "newsletter",
  initialState,
  reducers: {
    /*eslint-disable-next-line @typescript-eslint/no-unused-vars*/
    fetchNewslettersInit: (state, action) => {
      state.isLoadingNewsletter = true;
      state.isErrorNewsletter = false;
    },
    fetchNewslettersFailure: (state) => {
      state.isLoadingNewsletter = false;
      state.isErrorNewsletter = true;
    },
    fetchNewslettersSuccess: (
      state,
      action: PayloadAction<{
        newsletters: Newsletter[];
      }>,
    ) => {
      state.isLoadingNewsletter = false;
      state.isErrorNewsletter = false;
      state.newsletters = action.payload.newsletters;
      state.subscriptions = action.payload.newsletters.reduce(
        (subscriptions: Subscription[], newsletter: Newsletter) => {
          if (newsletter.subscribed) {
            subscriptions.push({
              newsletterID: newsletter.newsletterID,
              subscribed: newsletter.subscribed,
            });
          }
          return subscriptions;
        },
        [],
      );
    },
    setSubmitted: (state, action: PayloadAction<{ submitted: boolean }>) => {
      state.submitted = action.payload.submitted;
    },
    /*eslint-disable-next-line @typescript-eslint/no-unused-vars*/
    submittingInit: (state, action) => {
      state.submitting = true;
      state.submittingFailed = false;
      state.submitted = false;
    },
    submittingFailure: (state) => {
      state.submitting = false;
      state.submittingFailed = true;
      state.submitted = false;
    },
    submittingSuccess: (state) => {
      state.submitting = false;
      state.submittingFailed = false;
      state.submitted = true;
    },
    setSubscription: (
      state,
      action: PayloadAction<{
        selectedNewsletter: {
          newsletterID: number;
          subscribed: boolean;
        };
      }>,
    ) => {
      const newsletterState = updateSpecificStateObjectBasedOnId(
        action.payload?.selectedNewsletter,
        [...state.newsletters],
      );
      const subscriptionIndex = state.subscriptions.findIndex(
        (newsletter: Newsletter) =>
          newsletter.newsletterID ===
          action.payload?.selectedNewsletter.newsletterID,
      );

      // newsletter not present in subscription list, adding it
      if (subscriptionIndex === -1) {
        return {
          ...state,
          newsletters: newsletterState as Newsletter[],
          subscriptions: [
            ...state.subscriptions,
            action.payload?.selectedNewsletter,
          ],
        };
      }

      // remove newsletter from subscription list
      return {
        ...state,
        newsletters: newsletterState as Newsletter[],
        subscriptions: state.subscriptions.filter(
          (subscription: Subscription) =>
            subscription.newsletterID !==
            action.payload?.selectedNewsletter.newsletterID,
        ),
      };
    },

    authenticatedSetSubscription: (
      state,
      action: PayloadAction<{
        selectedNewsletter: {
          newsletterID: number;
          subscribed: boolean;
        };
      }>,
    ) => {
      const newsletterState = updateSpecificStateObjectBasedOnId(
        action.payload?.selectedNewsletter,
        [...state.newsletters],
      );
      const subscriptionState = updateSpecificStateObjectBasedOnId(
        action.payload?.selectedNewsletter,
        [...state.subscriptions],
      );

      const subscriptionIndex = state.subscriptions.findIndex(
        (subscription: Newsletter) =>
          subscription.newsletterID ===
          action.payload?.selectedNewsletter.newsletterID,
      );

      // newsletter not present in subscription list, adding it
      if (subscriptionIndex === -1) {
        return {
          ...state,
          newsletters: newsletterState as Newsletter[],
          subscriptions: [
            ...state.subscriptions,
            action.payload?.selectedNewsletter,
          ],
        };
      }

      return {
        ...state,
        newsletters: newsletterState as Newsletter[],
        subscriptions: subscriptionState as Subscription[],
      };
    },
  },
});

export const newsletterSliceSelector = (state: RootState): NewsletterState =>
  state.newsletter;
export const isLoadingNewsletterSelector = (state: RootState): boolean =>
  state.newsletter.isLoadingNewsletter;
export const isErrorNewsletterSelector = (state: RootState): boolean =>
  state.newsletter.isErrorNewsletter;

const { actions, reducer } = slice;

// Actions
export const {
  fetchNewslettersFailure,
  fetchNewslettersInit,
  fetchNewslettersSuccess,
  setSubscription,
  authenticatedSetSubscription,
  submittingFailure,
  submittingInit,
  submittingSuccess,
  setSubmitted,
} = actions;

export default reducer;
