import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import { NewsletterWithStatus } from "@/services/Model";

import { RootState } from "..";

export type NewsletterState = {
  newsletters: NewsletterWithStatus[];
  fetchingNewsletters: boolean;
  fetchingNewslettersError: boolean;
};

/* initial state */
const initialState: NewsletterState = {
  newsletters: [],
  fetchingNewsletters: false,
  fetchingNewslettersError: false,
};

/* reducer */
const slice = createSlice({
  name: "newsletter",
  initialState,
  reducers: {
    fetchNewsletters: (state) => {
      state.fetchingNewsletters = true;
      state.newsletters = [];
    },
    fetchNewslettersSuccess: (state, action) => {
      state.fetchingNewsletters = false;
      state.newsletters = action.payload as NewsletterWithStatus[];
    },
    fetchNewslettersError: (state) => {
      state.fetchingNewsletters = false;
      state.fetchingNewslettersError = true;
      state.newsletters = [];
    },

    subscribeNewsletter: (
      state,
      action: PayloadAction<{ newsletterID: number }>,
    ) => {
      const { newsletterID } = action.payload;
      state.newsletters = state.newsletters.map((newsletter) =>
        newsletter.newsletterID === newsletterID
          ? { ...newsletter, isLoading: true }
          : newsletter,
      );
    },
    subscribeNewsletterSuccess: (
      state,
      action: PayloadAction<{ newsletterID: number }>,
    ) => {
      const { newsletterID } = action.payload;
      state.newsletters = state.newsletters.map((newsletter) =>
        newsletter.newsletterID === newsletterID
          ? {
              ...newsletter,
              subscribed: true,
              subscribeSuccess: true,
              isLoading: false,
              hasError: false,
            }
          : newsletter,
      );
    },
    subscribeNewsletterError: (
      state,
      action: PayloadAction<{ newsletterID: number }>,
    ) => {
      state.newsletters = state.newsletters.map((newsletter) =>
        newsletter.newsletterID === action.payload.newsletterID
          ? {
              ...newsletter,
              subscribed: false,
              subscribeSuccess: false,
              isLoading: false,
              hasError: true,
            }
          : newsletter,
      );
    },

    unSubscribeNewsletter: (
      state,
      action: PayloadAction<{ newsletterID: number }>,
    ) => {
      const { newsletterID } = action.payload;
      state.newsletters = state.newsletters.map((newsletter) =>
        newsletter.newsletterID === newsletterID
          ? { ...newsletter, isLoading: true }
          : newsletter,
      );
    },
    unSubscribeNewsletterSuccess: (
      state,
      action: PayloadAction<{ newsletterID: number }>,
    ) => {
      const { newsletterID } = action.payload;
      state.newsletters = state.newsletters.map((newsletter) =>
        newsletter.newsletterID === newsletterID
          ? {
              ...newsletter,
              subscribed: false,
              isLoading: false,
              hasError: false,
            }
          : newsletter,
      );
    },
    unSubscribeNewsletterError: (
      state,
      action: PayloadAction<{ newsletterID: number }>,
    ) => {
      state.newsletters = state.newsletters.map((newsletter) =>
        newsletter.newsletterID === action.payload.newsletterID
          ? { ...newsletter, isLoading: false, hasError: true }
          : newsletter,
      );
    },

    newsletterHadSubscribeError: (
      state,
      action: PayloadAction<{ newsletterID: number }>,
    ) => {
      state.newsletters = state.newsletters.map((newsletter) =>
        newsletter.newsletterID === action.payload.newsletterID
          ? {
              ...newsletter,
              subscribed: false,
              isLoading: false,
              hasError: false,
            }
          : newsletter,
      );
    },

    newsletterHadUnSubscribeError: (
      state,
      action: PayloadAction<{ newsletterID: number }>,
    ) => {
      state.newsletters = state.newsletters.map((newsletter) =>
        newsletter.newsletterID === action.payload.newsletterID
          ? {
              ...newsletter,
              subscribed: true,
              isLoading: false,
              hasError: false,
            }
          : newsletter,
      );
    },

    resetSubscribeSuccess: (
      state,
      action: PayloadAction<{ newsletterID: number }>,
    ) => {
      state.newsletters = state.newsletters.map((newsletter) =>
        newsletter.newsletterID === action.payload.newsletterID
          ? {
              ...newsletter,
              subscribeSuccess: false,
            }
          : newsletter,
      );
    },
  },
});

export const fetchingNewslettersSelector = (state: RootState): boolean =>
  state.newsletter.fetchingNewsletters;

export const newsletterSelector = (state: RootState): NewsletterWithStatus[] =>
  state.newsletter.newsletters;

export const selectedNewsletterSelector = (
  state: RootState,
  newsletterID: number,
): NewsletterWithStatus =>
  state.newsletter.newsletters.find(
    (newsletter) => newsletter.newsletterID === newsletterID,
  ) as NewsletterWithStatus;

const { actions, reducer } = slice;

// Actions
export const {
  fetchNewsletters,
  fetchNewslettersSuccess,
  fetchNewslettersError,
  subscribeNewsletter,
  subscribeNewsletterSuccess,
  subscribeNewsletterError,
  unSubscribeNewsletter,
  unSubscribeNewsletterSuccess,
  unSubscribeNewsletterError,
  newsletterHadSubscribeError,
  newsletterHadUnSubscribeError,
  resetSubscribeSuccess,
} = actions;

export default reducer;
