import React from "react";
import { createRoot } from "react-dom/client";

import App from "./App";
import { container } from "./Brands";
import { log } from "./utils/logger";

if (!container) {
  log.error("Could not initialize App.");
  throw new Error(
    "Could not find container element with id 'la-newsletter-registration'.",
  );
}

const root = createRoot(container);
root.render(<App />);
