import React from "react";
import SVG from "react-inlinesvg";

import { ConfigContext } from "@/factory/apps/NoSSOApp";

import { BasicLinkButton, LinkButtonText } from "./styles";

export type LinkButtonProps = {
  iconName?: string;
  text: string;
  onButtonClick: () => void;
};

const LinkButton: React.FC<LinkButtonProps> = (
  props: LinkButtonProps,
): JSX.Element => {
  const { iconSourceURL } = React.useContext(ConfigContext);

  if (typeof props.iconName !== "undefined") {
    return (
      <BasicLinkButton onClick={props.onButtonClick}>
        <SVG src={`${iconSourceURL}${props.iconName}.svg`} />
        <LinkButtonText>{props.text}</LinkButtonText>
      </BasicLinkButton>
    );
  }

  return (
    <BasicLinkButton onClick={props.onButtonClick}>
      <LinkButtonText>{props.text}</LinkButtonText>
    </BasicLinkButton>
  );
};

export default LinkButton;
