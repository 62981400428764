import React from "react";

import { Views } from "@/factory/apps/NoSSOApp";

import HeaderHeadlines from "./HeaderHeadlines";
import HeaderText from "./HeaderText";
import RegistrationHeaderText from "./RegistrationHeaderText";
import { HeaderBackground, HeaderSection } from "./styles";

export type HeaderProps = {
  title: string;
  text?: string;
  descriptionHeadline?: string;
  descriptionItems?: string[];
  subtitle: string;
  view: Views;
  button?: LinkButtonProps;
};

export type LinkButtonProps = {
  iconName?: string;
  text: string;
  onButtonClick: () => void;
};

const Header: React.FC<HeaderProps> = (props: HeaderProps): JSX.Element => {
  return (
    <HeaderSection>
      <HeaderBackground view={props.view}>
        <HeaderHeadlines
          subtitle={props.subtitle}
          title={props.title}
        ></HeaderHeadlines>
      </HeaderBackground>
      {props.view === Views.Registration ? (
        <RegistrationHeaderText
          view={props.view}
          descriptionItems={props.descriptionItems}
          descriptionHeadline={props.descriptionHeadline}
        />
      ) : (
        <HeaderText view={props.view} text={props.text} button={props.button} />
      )}
    </HeaderSection>
  );
};
export default Header;
