import React from "react";

import {
  ConfigContext,
  NoSSOGlobalContext,
  Views,
} from "@/factory/apps/NoSSOApp";
import Header from "@/noSSOPartials/Header";

const Verified: React.FC = (): JSX.Element => {
  const { VerifiedTexts } = React.useContext(NoSSOGlobalContext);
  const { homePageURL } = React.useContext(ConfigContext);

  return (
    <React.Fragment>
      <Header
        subtitle={VerifiedTexts.subtitle}
        title={VerifiedTexts.title}
        text={VerifiedTexts.text}
        view={Views.Verified}
        button={{
          iconName: VerifiedTexts.buttonIconName,
          text: VerifiedTexts.buttonText,
          onButtonClick: () => window.open(homePageURL, "_self"),
        }}
      />
    </React.Fragment>
  );
};

export default Verified;
