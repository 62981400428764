import styled from "styled-components";

export type BasicLinkButtonProps = {
  display: string;
  marginTop: string;
  width: string;
  padding: string;
  maxWidth: string;
  flexDirection: string;
  desktop: {
    height: string;
    fontSize: string;
  };
  icon: {
    margin: string;
  };
};

export const BasicLinkButton = styled.button`
  display: ${(props) => props.theme.components.Buttons.Link.display};
  width: ${(props) => props.theme.components.Buttons.Link.width};
  height: ${(props) => props.theme.components.Buttons.Basic.height};
  flex-direction: ${(props) =>
    props.theme.components.Buttons.Link.flexDirection};
  align-items: center;
  justify-content: center;
  padding: ${(props) => props.theme.components.Buttons.Link.padding};
  border-width: ${(props) => props.theme.components.Buttons.Basic.borderWidth};
  border-style: ${(props) => props.theme.components.Buttons.Basic.borderStyle};
  border-color: ${(props) => props.theme.components.Buttons.Basic.borderColor};
  margin-top: ${(props) => props.theme.components.Buttons.Link.marginTop};
  background-color: ${(props) =>
    props.theme.components.Buttons.Basic.backgroundColor};
  border-radius: ${(props) =>
    props.theme.components.Buttons.Basic.borderRadius};
  color: ${(props) => props.theme.components.Buttons.Basic.color};
  font-family: ${(props) => props.theme.components.Buttons.Basic.fontFamily};
  font-size: ${(props) => props.theme.components.Buttons.Basic.fontSize};
  font-weight: ${(props) => props.theme.components.Buttons.Basic.fontWeight};
  letter-spacing: ${(props) =>
    props.theme.components.Buttons.Basic.letterSpacing};
  text-align: center;
  text-transform: uppercase;

  @media ${(props) => props.theme.global.breakpoints.desktop} {
    height: ${(props) => props.theme.components.Buttons.Link.desktop.height};
    font-size: ${(props) =>
      props.theme.components.Buttons.Link.desktop.fontSize};
    &:hover {
      border-color: ${(props) =>
        props.theme.components.Buttons.Basic.hover.borderColor};
      background-color: ${(props) =>
        props.theme.components.Buttons.Basic.hover.backgroundColor};
      color: ${(props) => props.theme.components.Buttons.Basic.hover.color};
      cursor: pointer;
      & svg {
        & path {
          fill: ${(props) => props.theme.components.Buttons.Basic.hover.color};
        }
      }
    }
  }
  &:focus {
    outline: 0;
  }

  &:active {
    outline: none;
  }

  & svg {
    display: flex;
    margin: ${(props) => props.theme.components.Buttons.Link.icon.margin};
  }
`;

export const ButtonWrapper = styled.div`
  width: ${(props) => props.theme.components.Buttons.Link.width};
  max-width: ${(props) => props.theme.components.Buttons.Link.maxWidth};
`;

export type LinkButtonTextProps = {
  marginLeft: string;
};

export const LinkButtonText = styled.span`
  display: flex;
`;
