import { DefaultTheme } from "styled-components";

import { Colors, FontFamily } from "../styles";

export const breakpoints = {
  mobileSmall: `(min-width: 0px) and (max-width: 380px)`,
  mobile: `(min-width: 380px) and (max-width: 599px)`,
  tablet: `(min-width: 599px) and (max-width: 970px)`,
  desktop: `(min-width: 970px) and (max-width: 3840px)`,
};

// create the actual theme here based on the definition in style.d.ts
export const welt: DefaultTheme = {
  global: {
    breakpoints: breakpoints,
    background: Colors.White,
    colors: {
      black: Colors.DarkBlack,
    },
    fonts: {
      fontFamily: FontFamily.MarkOTRegular,
      fontFamilyCondensed: FontFamily.MarkOTExtraLight,
      fontFamilyExtra: FontFamily.MarkOTHeavy,
    },
    transitions: {
      duration: "100ms",
      speedCurve: "ease-in",
    },
  },

  color: {
    text: Colors.DarkBlack,
    white: Colors.White,
    primary_brand_color: Colors.WeltBlue,
    border: Colors.WeltLightGrey,
    highlight: Colors.WeltOrange,
  },

  fontFamily: {
    heavy: FontFamily.MarkOTHeavy,
    light: FontFamily.MarkOTExtraLight,
    regular: FontFamily.MarkOTRegular,
  },

  radius: {
    s: "3px",
    m: "8px",
    l: "20px",
  },

  fontSize: {
    s: "11px",
    m: "14px",
    l: "16px",
    xl: "20px",
  },

  lineHeight: {
    s: "14px",
    m: "18px",
    l: "21px",
    xl: "26px",
  },

  gap: {
    s: "4px",
    m: "16px",
    l: "30px",
  },

  margin: {
    s: "8px",
    m: "16px",
    l: "20px",
    xl: "25px",
  },

  padding: {
    xs: "5px",
    s: "8px",
    m: "16px",
    l: "20px",
    xl: "25px",
  },
} as DefaultTheme;
