import { combineReducers } from "redux";

import applicationReducer from "./application/slice";
import newsletterReducer from "./newsletter/slice";
import userReducer from "./user/slice";

/* combine different domain reducers */
const rootReducer = combineReducers({
  application: applicationReducer,
  newsletter: newsletterReducer,
  user: userReducer,
});

export default rootReducer;
