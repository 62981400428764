import React from "react";

import Brands from "@/Brands";
import * as apps from "@/factory/apps";
import { log } from "@/utils/logger";

export type AppFactoryProps = {
  brand: Brands;
};

export type HandleProps = {
  canHandle: (props: AppFactoryProps) => boolean;
  renderComponent: (props: AppFactoryProps) => JSX.Element;
};

const AppFactory: React.FC<AppFactoryProps> = (props): JSX.Element => {
  const FoundApp = Object.values(apps).find((app) => app.canHandle(props));

  if (!FoundApp) {
    log.error("Could not create an App with the given properties:", props);
    throw new Error("Could not create an App");
  }

  return FoundApp.renderComponent(props);
};

export default AppFactory;
