import styled from "styled-components";

import { fadeInAnimation, pulse } from "@/styles/common";

export const StyledInformationWrapper = styled.span`
  width: 100%;
  height: 44px;
  display: flex;
  align-items: center;
  justify-content: center;

  animation: ${fadeInAnimation} 0.5s ease-in-out;
`;

export const StyledInformationNote = styled.div`
  text-align: center;
  font-family: ${(props) => props.theme.fontFamily.heavy};
  font-size: ${(props) => props.theme.fontSize.m};
  line-height: ${(props) => props.theme.lineHeight.m};
  color: ${(props) => props.theme.color.primary_brand_color};

  text-transform: uppercase;
  margin-right: ${(props) => props.theme.margin.m};

  &.pulse {
    animation: ${pulse} 1s infinite;
  }
`;
