import styled from "styled-components";

export const StyledChip = styled.a`
  background: ${(props) => props.theme.color.white};
  border: 1px solid ${(props) => props.theme.color.border};
  border-radius: ${(props) => props.theme.radius.l};

  text-decoration: none;
  text-transform: uppercase;
  color: ${(props) => props.theme.color.primary_brand_color};
  font-size: ${(props) => props.theme.fontSize.s};
  line-height: ${(props) => props.theme.lineHeight.m};
  font-family: ${(props) => props.theme.fontFamily.heavy};
  padding: ${(props) => props.theme.padding.xs}
    ${(props) => props.theme.padding.m};
  transition: all 0.15s ease-in-out;

  :hover {
    background-color: ${(props) => props.theme.color.border};
    border: 1px solid ${(props) => props.theme.color.border};
    color: ${(props) => props.theme.color.highlight};
    cursor: pointer;
    transition: all 0.15s ease-in-out;
  }
`;
