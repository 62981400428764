import { Punctuation, Umlauts } from "./characterUnicodes";
import { DefaultContent, NoSSODefaultContent } from "./Model";

export const bild: NoSSODefaultContent = {
  ConfirmationTexts: {
    subtitle: "Fast geschafft!",
    title: "Bestätigen Sie Ihre E-Mail-Adresse",
    text: "In Kürze erhalten Sie zum Abschluss Ihrer Newsletter-Anmeldung einen <b>Aktivierungslink</b> an Ihre <b>E-Mail-Adresse</b>.",
    sectionHeader: "Ihre persönliche Auswahl",
    buttonIconName: "home",
    buttonText: "Zurück zur Startseite",
  },

  EditedTexts: {
    subtitle: "Geschafft!",
    title: "Ihre Änderungen wurden erfolgreich übernommen.",
    text: "Alle Newsletter von BILD finden Sie unter bild.de/newsletter.",
    sectionHeader: "Ihre persönliche Auswahl",
    buttonIconName: "home",
    buttonText: "Zurück zur Startseite",
  },

  FallbackTexts: {
    subtitle: "Uups!",
    title: "Da ist etwas schief gelaufen.",
    text: "Bitte laden Sie die Seite neu oder versuchen es zu einem späteren Zeitpunkt.",
    buttonIconName: "refresh",
    buttonText: "seite neu laden",
  },

  FallbackTextsSectionUI: {
    title: "Uups, da ist etwas schief gelaufen.",
    text: "Bitte laden Sie die Seite neu oder versuchen es zu einem späteren Zeitpunkt.",
    buttonIconName: "refresh",
    buttonText: "Seite neu laden",
  },

  RegistrationTexts: {
    title: "Wählen Sie Ihre Lieblings-Newsletter",
    subtitle: "Immer bestens informiert",
    descriptionHeadline:
      "Top-News, Sport-Highlights und Ratgeber für den Alltag – kostenlos an Ihre E-Mail-Adresse in nur drei Schritten:",
    descriptionItems: [
      "Wählen Sie Ihre Newsletter und klicken Sie auf „Auswählen“",
      "Geben Sie am Ende der Seite Ihre E-Mail Adresse ein",
      "Klicken Sie auf „kostenlos abonnieren“",
    ],
    sectionHeaders: {
      topic: "Weitere Newsletter",
      regional: "Regio Newsletter",
    },
  },

  VerifiedTexts: {
    subtitle: "Danke!",
    title: "Ihre E-Mail-Adresse wurde bestätigt.",
    text: "Freuen Sie sich auf Ihren persönlichen und kostenlosen Newsletter.",
    buttonIconName: "home",
    buttonText: "Zurück zur Startseite",
  },
  FinePrintTexts: {
    data: "Ja, ich möchte, dass die Axel Springer Deutschland GmbH mir Newsletter per E-Mail und/oder Push-Nachrichten mit Produktangeboten und/oder Medienangebote der Axel Springer Medien auf Basis meiner ",
    optIn:
      "Wenn Sie unsere Newsletter nicht mehr erhalten möchten, können Sie diese jederzeit abbestellen, indem Sie einfach auf den Link „Abbestellen“ in der Fußzeile des Newsletters klicken oder eine E-Mail an info@bild.de senden.",
  },
};

export const aubi: NoSSODefaultContent = {
  ConfirmationTexts: {
    subtitle: "Fast geschafft!",
    title: `Best${Umlauts.ae}tigen Sie Ihre E-Mail-Adresse`,
    text: `In K${Umlauts.ue}rze erhalten Sie zum Abschluss Ihrer Newsletter-Anmeldung einen <b>Aktivierungslink</b> an Ihre <b>E-Mail-Adresse</b>.`,
    sectionHeader: `Ihre pers${Umlauts.oe}nliche Auswahl`,
    buttonIconName: "home",
    buttonText: `Zur${Umlauts.ue}ck zur Startseite`,
  },

  EditedTexts: {
    subtitle: "Geschafft!",
    title: `Ihre ${Umlauts.Ae}nderungen wurden übernommen.`,
    text: "Alle Newsletter von AUTO BILD finden Sie unter autobild.de/newsletter.",
    sectionHeader: `Ihre pers${Umlauts.oe}nliche Auswahl`,
    buttonIconName: "home",
    buttonText: `Zur${Umlauts.ue}ck zur Startseite`,
  },

  FallbackTexts: {
    subtitle: "Uups!",
    title: "Da ist etwas schief gelaufen.",
    text: `Bitte laden Sie die Seite neu oder versuchen es zu einem sp${Umlauts.ae}teren Zeitpunkt.`,
    buttonIconName: "refresh",
    buttonText: "seite neu laden",
  },

  FallbackTextsSectionUI: {
    title: "Uups, da ist etwas schief gelaufen.",
    text: `Bitte laden Sie die Seite neu oder versuchen es zu einem sp${Umlauts.ae}teren Zeitpunkt.`,
    buttonIconName: "refresh",
    buttonText: "Seite neu laden",
  },

  RegistrationTexts: {
    title: `W${Umlauts.ae}hlen Sie Ihre Lieblingsnewsletter`,
    subtitle: "Immer bestens informiert",
    descriptionHeadline: `Immer bestens informiert sein und fr${Umlauts.ue}her wissen, was AUTO BILD bewegt – kostenlos an Ihre E-Mail-Adresse in nur drei Schritten:`,
    descriptionItems: [
      "Wählen Sie Ihre Newsletter und klicken Sie auf „Auswählen“",
      "Geben Sie am Ende der Seite Ihre E-Mail Adresse ein",
      "Klicken Sie auf „kostenlos abonnieren“",
    ],
    sectionHeaders: {
      topic: "Weitere Newsletter",
    },
  },

  VerifiedTexts: {
    subtitle: "Danke!",
    title: `Ihre E-Mail-Adresse wurde best${Umlauts.ae}tigt.`,
    text: `Freuen Sie sich auf Ihren pers${Umlauts.oe}nlichen und kostenlosen Newsletter.`,
    buttonIconName: "home",
    buttonText: `Zur${Umlauts.ue}ck zur Startseite`,
  },
  FinePrintTexts: {
    data: `Ich m${Umlauts.oe}chte, dass die Axel Springer Deutschland GmbH und die Axel Springer Auto Verlag GmbH mir k${Umlauts.ue}nftig Newsletter per E-Mail mit interessanten Angeboten aus dem Bereich Automobil und Verkehr sendet.`,
    optIn: `Wenn Sie unsere Newsletter nicht mehr erhalten m${Umlauts.oe}chten, k${Umlauts.oe}nnen Sie diese jederzeit abbestellen, indem Sie einfach auf den Link ${Punctuation.quote}Abbestellen${Punctuation.quote} in der Fu${Umlauts.ss}zeile des Newsletters klicken oder eine E-Mail an datenschutz@autobild.de senden.`,
  },
};

export const cobi: NoSSODefaultContent = {
  ConfirmationTexts: {
    subtitle: "Fast geschafft!",
    title: `Best${Umlauts.ae}tigen Sie Ihre E-Mail-Adresse`,
    text: `In K${Umlauts.ue}rze erhalten Sie zum Abschluss Ihrer
     Newsletter-Anmeldung einen <b> Aktivierungslink </b> an Ihre
    <b> E-Mail-Adresse. </b>`,
    sectionHeader: `Ihre pers${Umlauts.oe}nliche Auswahl`,
    buttonIconName: "home",
    buttonText: `Zur${Umlauts.ue}ck zur Startseite`,
  },

  EditedTexts: {
    subtitle: "Geschafft!",
    title: `Ihre ${Umlauts.Ae}nderungen wurden erfolgreich ${Umlauts.ue}bernommen.`,
    text: "Alle Newsletter von COMPUTER BILD finden Sie unter computerbild.de/newsletter.",
    sectionHeader: `Ihre pers${Umlauts.oe}nliche Auswahl`,
    buttonIconName: "home",
    buttonText: `Zur${Umlauts.ue}ck zur Startseite`,
  },

  FallbackTexts: {
    subtitle: "Uups!",
    title: "Da ist etwas schief gelaufen.",
    text: `Bitte laden Sie die Seite neu oder versuchen es zu einem sp${Umlauts.ae}teren Zeitpunkt.`,
    buttonIconName: "refresh",
    buttonText: "Seite neu laden",
  },

  FallbackTextsSectionUI: {
    title: "Uups, da ist etwas schief gelaufen.",
    text: `Bitte laden Sie die Seite neu oder versuchen es zu einem sp${Umlauts.ae}teren Zeitpunkt.`,
    buttonIconName: "refresh",
    buttonText: "Seite neu laden",
  },

  RegistrationTexts: {
    title: `W${Umlauts.ae}hlen Sie Ihre Lieblingsnewsletter`,
    subtitle: "Immer bestens informiert",
    descriptionHeadline:
      "Abonnieren Sie hier die Newsletter von COMPUTER BILD und nutzen Sie viele kostenlose Vorteile in nur drei Schritten:",

    descriptionItems: [
      `Wählen Sie Ihre Newsletter und klicken Sie auf „Ausw${Umlauts.ae}hlen“`,
      "Geben Sie am Ende der Seite Ihre E-Mail Adresse ein",
      "Klicken Sie auf „kostenlos abonnieren“",
    ],
    sectionHeaders: {
      topic: "Weitere Newsletter",
    },
  },

  VerifiedTexts: {
    subtitle: "Vielen Dank!",
    title: `Ihre E-Mail-Adresse wurde best${Umlauts.ae}tigt.`,
    text: `Freuen Sie sich auf Ihren pers${Umlauts.oe}nlichen und kostenlosen Newsletter.`,
    buttonIconName: "home",
    buttonText: `Zur${Umlauts.ue}ck zur Startseite`,
  },
  FinePrintTexts: {
    data: `Ich m${Umlauts.oe}chte, dass die Axel Springer Deutschland GmbH und die COMPUTER BILD Digital GmbH mir k${Umlauts.ue}nftig Newsletter mit interessanten Angeboten aus dem Bereich Telekommunikation, Unterhaltungselektronik, Computer, Software und Preisvergleichen sendet.`,
    optIn: `Wenn Sie unsere Newsletter nicht mehr erhalten m${Umlauts.oe}chten, k${Umlauts.oe}nnen Sie diese jederzeit abbestellen, indem Sie einfach auf den Link ${Punctuation.quote}Abbestellen${Punctuation.quote} in der Fu${Umlauts.ss}zeile des Newsletters klicken oder eine E-Mail an datenschutz@computerbild.de senden.`,
  },
};

export const welt: DefaultContent = {
  ErrorBoundaryContext: {
    title: "Es tut uns leid, Es gab einen Fehler",
    text: "Wir werden versuchen den Fehler so schnell wie möglich zu beheben. Bitte versuchen Sie die Seite neu zu laden.",
    buttonText: "Seite neuladen",
  },

  SubscribeButtonContext: {
    notSubscribed: "Anmelden und Bestellen",
    successfulSubscribed: "Vielen Dank.",
    subscribed: "Abbestellen",
  },

  HeaderContext: {
    loggedOut: {
      title: `Bitte melden Sie sich an`,
      intro: `Um die Newsletter beziehen zu können, melden Sie sich bitte an oder registrieren Sie sich`,
      register: "hier.",
      login: "Anmelden",
    },

    loggedIn: {
      title: "Ihre abonnierten Newsletter im Überblick",
      intro: "Sie erhalten die Newsletter an",
      settings: `Sollten Sie unsere Newsletter unter einer anderen E-Mail Adresse abonniert haben, k${Umlauts.oe}nnen Sie hier Ihre`,
      noNewsletter: "Es sind noch keine Newsletter aktiv.",
      noNewsletterTitle: "Ihre künftige Newsletter-Übersicht",
      settingsLink: `Einstellungen ${Umlauts.ae}ndern`,
      showAllButton: "Alle anzeigen",
    },
  },

  NavigationContext: {
    headline: "Nach Themen durchsuchen",
  },

  FeaturedContext: {
    headline: "Unsere Top Newsletter",
  },

  LoginDialogContext: {
    title: "Legen Sie ein Konto an oder melden Sie sich an",
    introRegister:
      "Um den Newsletter zu erhalten, melden Sie sich bitte an. Falls Sie noch keinen Zugang haben, können Sie sich hier registrieren.",
    introLogin: "Haben Sie bereits ein Konto?",
    contactUs: "Rufen Sie uns an:",
    telephone: "0800 – 951 50 00",
    imprint: "Impressum",
    privacy: "Datenschutz",
    register: "Registrieren",
    login: "Anmelden",
  },

  LoadingContext: {
    newsletter: "Newsletter-Verwaltung wird geladen ...",
    user: "Benutzerdaten werden geladen ...",
  },
};
