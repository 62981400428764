const rootFontSize = 16;
const unitPrecision = 5;

function toFixed(num: number, precision: number): number {
  const multiplier = Math.pow(10, precision + 1);
  const wholeNumber = Math.floor(num * multiplier);

  return (Math.round(wholeNumber / 10) * 10) / multiplier;
}

export function rem(px: number): string {
  return `${toFixed(px / rootFontSize, unitPrecision)}rem`;
}
