// use unicode number here because react does not parse HTML entities
// https://shripadk.github.io/react/docs/jsx-gotchas.html

export const Umlauts = {
  ae: String.fromCharCode(228),
  Ae: String.fromCharCode(196),
  ue: String.fromCharCode(252),
  Ue: String.fromCharCode(220),
  oe: String.fromCharCode(246),
  Oe: String.fromCharCode(214),
  ss: String.fromCharCode(223),
};

export const Punctuation = {
  quote: String.fromCharCode(34),
};

export default Umlauts;
