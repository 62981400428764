import RegistrationBox from "./RegistrationBox";
import {
  StyledBoxProps,
  StyledBoxWrapperProps,
  StyledHeadlineProps,
  StyledOptInWrapperProps,
} from "./styles";

export type RegistrationBoxStyles = {
  OptIn: StyledOptInWrapperProps;
  Headline: StyledHeadlineProps;
  Wrapper: StyledBoxWrapperProps;
  Box: StyledBoxProps;
};

export default RegistrationBox;
