import styled from "styled-components";

export const StyledErrorSection = styled.div`
  display: flex;
  height: 336px;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 16px;
  border: ${(props) =>
      props.theme.otherNewsletters.Card.CardStyle.borderWidth}px
    solid ${(props) => props.theme.otherNewsletters.Card.CardStyle.borderColor};
  background: ${(props) =>
    props.theme.otherNewsletters.Card.CardStyle.backgroundColor};
  border-radius: ${(props) =>
    props.theme.otherNewsletters.Card.CardStyle.borderRadius}px;
  text-align: center;
`;

export const StyledButtonWrapper = styled.div`
  width: 100%;
  @media ${(props) => props.theme.global.breakpoints.desktop} {
    width: 233px;
  }
  @media ${(props) => props.theme.global.breakpoints.mobileSmall} {
    width: 260px;
  }
`;
