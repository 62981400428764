import React, { ReactNode } from "react";
import SVG from "react-inlinesvg";

import { ConfigContext } from "@/factory/apps/NoSSOApp";
import { Icons } from "@/styles/styles";

import { CloseIconButton, InfoboxModal } from "./styles";

export type InfoboxProps = {
  text?: string;
  isVisible?: boolean;
  onClick: () => void;
  children?: ReactNode;
};

const Infobox: React.FC<InfoboxProps> = (props: InfoboxProps): JSX.Element => {
  const { iconSourceURL } = React.useContext(ConfigContext);
  return (
    <InfoboxModal isVisible={props.isVisible}>
      <CloseIconButton onClick={() => props.onClick()}>
        <SVG src={`${iconSourceURL}${Icons.Close}.svg`} />
      </CloseIconButton>
      {props.text}
    </InfoboxModal>
  );
};

export default Infobox;
