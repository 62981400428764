import React, { ReactNode } from "react";

import { StyledLink } from "./styles";

export type LinkProps = {
  children?: ReactNode;
  href?: string;
  target?: string;
  onClick?: () => void;
  className?: string;
  dataQa?: string;
};

const Link: React.FC<LinkProps> = (props: LinkProps): JSX.Element => {
  return (
    <StyledLink
      target={props.target}
      href={props.href}
      onClick={props.onClick}
      className={props.className}
      data-qa={props.dataQa}
    >
      {props.children}
    </StyledLink>
  );
};

export default Link;
