import styled from "styled-components";

export const StyledLoadingAnimationWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: ${(props) => props.theme.gap.s};

  text-transform: uppercase;
  color: ${(props) => props.theme.color.text};
  font-family: ${(props) => props.theme.fontFamily.regular};
  font-size: ${(props) => props.theme.fontSize.s};
  line-height: ${(props) => props.theme.lineHeight.m};
  margin: ${(props) => props.theme.margin.xl};

  &.inverted {
    color: ${(props) => props.theme.color.white};
  }
`;
