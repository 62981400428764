import { DefaultTheme } from "styled-components";

import { Colors, FontFamily, HeaderImages } from "../styles";

export const breakpoints = {
  mobileSmall: `(min-width: 0px) and (max-width: 360px)`,
  mobile: `(min-width: 361px) and (max-width: 745px)`,
  desktop: `(min-width: 745px) and (max-width: 3840px)`,
};

enum FontWeight {
  Normal = 400,
  Bold = 700,
  Heavy = 900,
}

enum BorderRadius {
  Primary = "4px",
  Secondary = "8px",
}

enum BorderWidth {
  Primary = "1px",
}

// create the actual theme here based on the definition in style.d.ts
export const cobi: DefaultTheme = {
  global: {
    breakpoints: breakpoints,
    background: Colors.White,
    colors: {
      black: Colors.DarkRaven,
    },
    fonts: {
      fontFamily: FontFamily.ProximaNova,
      fontFamilyCondensed: FontFamily.ProximaNovaCondensed,
      fontFamilyExtra: FontFamily.ProximaNova,
    },
    transitions: {
      duration: "100ms",
      speedCurve: "ease-in",
    },
  },

  // basic components:
  components: {
    Buttons: {
      Basic: {
        borderWidth: "1px",
        borderStyle: "solid",
        borderColor: Colors.Grey,
        borderRadius: BorderRadius.Primary,
        width: "100%",
        height: "48px",
        backgroundColor: Colors.Transparent,
        color: Colors.Raven,
        fontSize: "16px",
        fontFamily: FontFamily.ProximaNovaCondensed,
        fontWeight: FontWeight.Bold,
        letterSpacing: "0.03em",
        desktop: {
          height: "48px",
        },
        hover: {
          backgroundColor: Colors.LightGras,
          borderColor: Colors.Gras,
          color: Colors.Gras,
        },
        subscribed: {
          backgroundColor: Colors.Transparent,
          borderColor: Colors.Gras,
          color: Colors.Gras,
        },
        checkmark: {
          color: Colors.Gras,
        },
      },
      Link: {
        display: "flex",
        width: "100%",
        marginTop: "14px",
        padding: "0px",
        maxWidth: "250px",
        flexDirection: "row",
        desktop: {
          height: "48px",
          fontSize: "16px",
        },
        icon: {
          margin: "0 10px 0 0",
        },
      },
    },

    SectionHeader: {
      Headline: {
        display: "flex",
        color: Colors.DarkRaven,
        fontFamily: FontFamily.ProximaNova,
        fontSize: "24px",
        fontWeight: FontWeight.Bold,
        lineHeight: "30px",

        desktop: {
          fontSize: "30px",
          lineHeight: "34px",
        },
      },
      Border: {
        display: "none",
        borderColor: Colors.Primary,
        marginTop: "12px",
        height: "4px",
        width: "54px",

        desktop: {
          marginTop: "16px",
        },
      },
    },
    Schedule: {
      Text: {
        color: Colors.Red,
        fontFamily: FontFamily.ProximaNovaCondensed,
        fontSize: "16px",
        fontWeight: FontWeight.Bold,
        lineHeight: "23px",
        marginLeft: "10px",
        desktop: {
          fontSize: "18px",
        },
      },

      Wrapper: {
        marginTop: "6px",
      },
    },
    Text: {
      color: Colors.DarkRaven,
      fontFamily: FontFamily.ProximaNova,
      fontSize: "16px",
      lineHeight: "22px",
      margin: "12px 0",
    },
    InputField: {
      borderRadius: BorderRadius.Primary,
      borderColor: Colors.Grey,
      borderWidth: BorderWidth.Primary,
      backgroundColor: Colors.White,
      color: Colors.DarkGrey,
      fontFamily: FontFamily.ProximaNova,
      fontSize: "18px",

      hover: {
        borderColor: Colors.Raven,
        color: Colors.DarkRaven,
      },

      focus: {
        borderColor: Colors.DarkRaven,
        color: Colors.DarkRaven,
      },

      error: {
        borderColor: Colors.Red,
        color: Colors.Red,
      },

      disabled: {
        borderColor: Colors.Grey,
        color: Colors.Grey,
      },
    },
    SubmitButton: {
      backgroundColor: Colors.Gras,
      backgroundColorHover: Colors.DarkGras,
      color: Colors.White,
      borderRadius: BorderRadius.Primary,
      fontFamily: FontFamily.ProximaNovaCondensed,
      fontWeight: FontWeight.Bold,
      fontSize: "16px",

      disabled: {
        backgroundColor: Colors.LighterRaven,
        color: Colors.LightRaven,
        fontSize: "16px",
        fontFamily: FontFamily.ProximaNovaCondensed,
      },
    },
    CheckBox: {
      borderWidth: BorderWidth.Primary,
      borderRadius: BorderRadius.Primary,
      borderColor: Colors.Raven,
    },
    FinePrint: {
      fontFamily: FontFamily.ProximaNova,
      fontSize: "16px",
      fontStyle: "normal",
      fontWeight: FontWeight.Normal,
      lineHeight: "20px",
    },
  },

  // sections:
  mainContent: {
    maxWidth: "710px;",
  },

  header: {
    DescriptionHeadline: {
      lineHeight: "130%",
      fontWeight: "bold",
      fontStyle: "normal",
      fontSize: "18px",
    },
    DescriptionList: {
      fontSize: "18px",
      padding: "8px 0px 0px 0px",
      gap: "8px",
    },
    Description: {
      gap: "13px",
      alignItems: "center",
      justifyContent: "space-between",
    },
    HeaderBackground: {
      backgroundColor: Colors.Red,
      backgroundImage: HeaderImages.Cobi,
      desktop: {
        justifyContent: "center",
      },
    },
    HeaderHeadline: {
      textAlign: "center",
      width: "100%",
      desktop: {
        width: "50%",
        marginLeft: "40px",
        textAlign: "center",
        padding: "50px 0 58px 0",
      },
    },
    Headline: {
      color: Colors.White,
      fontSize: "35px",
      fontWeight: FontWeight.Bold,
      fontFamily: FontFamily.ProximaNova,
      lineHeight: "35px",
      width: "100%",
      margin: "5px 0 60px",

      desktop: {
        fontSize: "50px",
        lineHeight: "60px",
        width: "100%",
      },
      mobile: {
        margin: "0 0 30px",
      },
      mobileSmall: {
        fontSize: "34px",
      },
    },
    Subtitle: {
      backgroundColor: Colors.Transparent,
      color: Colors.White,
      padding: "0 0px",
      fontSize: "20px",
      lineHeight: "24px",
      fontWeight: FontWeight.Bold,
      fontFamily: FontFamily.ProximaNova,

      desktop: {
        fontSize: "25px",
        lineHeight: "30px",
      },
    },
    TextWrapper: {
      borderRadius: BorderRadius.Secondary,
      boxShadow: "0px 8px 24px rgba(33, 37, 41, 0.1)",
      backgroundColor: Colors.White,
      padding: "22px 13px",
      mobile: {
        width: "auto",
        height: "auto",
        padding: "12px",
      },
    },
    TextProps: {
      fontSize: "18px",
      lineHeight: "24px",
      color: Colors.Raven,
      fontFamily: FontFamily.ProximaNova,
      desktop: {
        maxWidth: "100%",
        fontSize: "20px",
        lineHeight: "26px",
      },
    },
  },

  registrationBox: {
    Headline: {
      fontSize: "20px",
      fontWeight: FontWeight.Bold,
      fontFamily: FontFamily.ProximaNovaCondensed,
      desktop: {
        fontSize: "25px",
      },
    },
    Wrapper: {
      backgroundColor: Colors.White,
    },
    Box: {
      backgroundColor: Colors.White,
      borderColor: Colors.Grey,
      borderRadius: BorderRadius.Primary,
      borderWidth: BorderWidth.Primary,
    },
    OptIn: {
      padding: "20px 0",
      desktop: {
        padding: "30px 0 12px",
      },
    },
  },

  featuredNewsletters: {
    Header: {
      alignSelf: "flex-start",
      margin: "20px 0 12px",
      desktop: {
        alignSelf: "flex-start",
        margin: "50px 0 16px",
      },
    },
    Card: {
      CardWrapper: {
        border: "1px solid " + Colors.Grey,
        borderRadius: BorderRadius.Primary,
        height: "413px",
        marginBottom: "20px",

        desktop: {
          height: "242px",
          flexDirection: "row-reverse",
        },
      },
      ContentWrapper: {
        padding: "12px",
      },
      ImageWrapper: {
        borderRadius: BorderRadius.Primary,
        height: "188px",
        width: "100%",
        backgroundColor: Colors.Grey,

        desktop: {
          height: "100%",
          width: "430px",
        },
      },
      ImageTitle: {
        display: "none",
        border: "2px solid " + Colors.White,
        padding: "7px",
        color: Colors.White,
        fontFamily: FontFamily.ProximaNovaCondensed,
        fontWeight: FontWeight.Bold,
        fontSize: "24px",
        lineHeight: "28px",
        margin: "10px",
      },
      Title: {
        color: Colors.DarkRaven,
        fontFamily: FontFamily.ProximaNovaCondensed,
        fontWeight: FontWeight.Bold,
        fontSize: "24px",
        lineHeight: "30px",
      },
    },
  },

  otherNewsletters: {
    Header: {
      display: "none",
      desktop: {
        margin: "8px 0 6px",
      },
    },
    Section: {
      width: "100%",
      justifyContent: "space-between",
      desktop: {
        justifyContent: "start",
      },
    },
    Card: {
      CardStyle: {
        backgroundColor: Colors.White,
        borderWidth: BorderWidth.Primary,
        borderColor: Colors.Grey,
        borderRadius: BorderRadius.Primary,
        width: "46%",
        minHeight: "250px",
        margin: "7.5px 6px",
        padding: "0px",
        desktop: {
          height: "266px",
          heightLastCard: "290px",
          margin: "10px 6px",
          width: "228px",
          padding: "0px",
        },
        mobileSmall: {
          minHeight: "140px",
          padding: "0px",
        },
      },
      Sponsored: {
        top: "-13px",
        color: Colors.LightRaven,
        fontSize: "10px",
        fontFamily: FontFamily.ProximaNova,
        desktop: {
          top: "-14px",
          fontSize: "11px",
        },
      },
      IconWrapper: {
        imgWidth: "90px",
        imgHeight: "90px",
        desktop: {
          margin: "4px",
          imgWidth: "120px",
          imgHeight: "120px",
        },
        mobileSmall: {
          marginRight: "24px",
        },
      },
      TextWrapper: {
        padding: "8px 9px 14px 9px",
        mobileSmall: {
          alignItems: "center",
          padding: "8px",
        },
        desktop: {
          padding: "8px 14px 14px 14px",
        },
      },
      TitleWrapper: {
        margin: "0px",
        mobileSmall: {
          minHeight: "28px",
        },
      },
      Title: {
        color: Colors.Black,
        fontFamily: FontFamily.ProximaNovaCondensed,
        fontSize: "22px",
        width: "120px",
        margin: "0px",
        desktop: {
          width: "200px",
          fontSize: "22px",
        },
      },
      InfoIconButton: {
        display: "none",
        width: "24px",
        height: "24px",
      },
      InfoboxModal: {
        fontSize: "16px",
        padding: "11px 12px",
      },
      Schedule: {
        color: Colors.Red,
        fontFamily: FontFamily.ProximaNovaCondensed,
        fontSize: "16px",
        fontWeight: FontWeight.Bold,
        margin: "2px 0 6px",
        desktop: {
          fontSize: "18px",
        },
        mobileSmall: {
          fontSize: "16px",
        },
      },
      Text: {
        color: Colors.DarkRaven,
        fontFamily: FontFamily.ProximaNovaCondensed,
        fontSize: "16px",
        lineHeight: "23px",
        textAlign: "center",
        desktop: {
          padding: "0px 20px",
        },
      },
    },
  },

  selectedNewsletters: {
    Section: {
      desktop: {
        maxWidth: "712px",
        padding: "0px",
      },
    },
    Header: {
      margin: "20px 0 12px",
      desktop: {
        margin: "50px 0 16px 6px",
      },
    },
    Wrapper: {
      padding: "0px",
    },
    Card: {
      border: "1px solid " + Colors.Grey,
      borderRadius: BorderRadius.Primary,
      desktop: {
        maxWidth: "225px",
        height: "100px",
        alignItems: "center",
      },
    },
    Title: {
      color: Colors.DarkRaven,
      fontFamily: FontFamily.ProximaNova,
      fontSize: "22px",
      fontWeight: FontWeight.Bold,
      lineHeight: "24px",
      desktop: {
        textAlign: "center",
        fontSize: "22px",
        lineHeight: "26px",
      },
    },
  },
} as DefaultTheme;
