import { log } from "@/utils/logger";

export enum Environment {
  Development = "development",
  Staging = "staging",
  Production = "production",
  Test = "test",
}

let theEnvironment = Environment.Production;

export const environment = (): Environment => {
  try {
    if (Object.values(Environment).includes(__ENVIRONMENT__ as Environment)) {
      theEnvironment = __ENVIRONMENT__ as Environment;
    } else {
      log.warning(
        "Current environment flag is not known to the application. Falling back to environment production. Given environment flag:",
        __ENVIRONMENT__,
      );
    }
  } catch (e) {
    log.error("Failed to parse environment flag");
  }

  return theEnvironment;
};

export const isProduction = (): boolean => {
  return environment() === Environment.Production;
};

export const isStaging = (): boolean => {
  return environment() === Environment.Staging;
};

export const isDevelopment = (): boolean => {
  return environment() === Environment.Development;
};
