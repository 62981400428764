import styled from "styled-components";

export const StyledCard = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  text-align: left;
  border-bottom: 1px solid ${(props) => props.theme.color.border};

  :last-child {
    border-bottom: none;
  }

  @media ${(props) => props.theme.global.breakpoints.tablet} {
    width: calc(50% - 16px);
    border-bottom: none;
  }

  @media ${(props) => props.theme.global.breakpoints.desktop} {
    width: 283px;
    border-bottom: none;
  }
`;

type CardImageProps = {
  src: string;
};
export const StyledCardImage = styled.div<CardImageProps>`
  width: 100%;
  height: 164px;
  background-image: url(${(props) => props.src});
  background-repeat: no-repeat;
  background-size: cover;
  border-radius: ${(props) => props.theme.radius.s};

  @media ${(props) => props.theme.global.breakpoints.tablet} {
    width: 100%;
  }

  @media ${(props) => props.theme.global.breakpoints.desktop} {
    width: 283px;
    height: 135px;
  }
`;

export const StyledCardTitle = styled.h3`
  margin: ${(props) => props.theme.margin.l} 0
    ${(props) => props.theme.margin.s} 0;
  font-family: ${(props) => props.theme.fontFamily.heavy};
  font-size: ${(props) => props.theme.fontSize.m};
  line-height: ${(props) => props.theme.lineHeight.l};
  text-transform: uppercase;

  @media ${(props) => props.theme.global.breakpoints.desktop} {
    font-size: ${(props) => props.theme.fontSize.l};
  }

  &.featured {
    margin: ${(props) => props.theme.margin.s} 0
      ${(props) => props.theme.margin.s} 0;

    @media ${(props) => props.theme.global.breakpoints.desktop} {
      font-size: ${(props) => props.theme.fontSize.xl};
      padding: 0 0 ${(props) => props.theme.padding.m} 0;
    }
  }
`;

export const StyledCardIntro = styled.p`
  width: 100%;
  max-width: 450px;
  font-family: ${(props) => props.theme.fontFamily.regular};
  font-size: ${(props) => props.theme.fontSize.m};
  line-height: ${(props) => props.theme.lineHeight.l};
  margin: 0 0 ${(props) => props.theme.margin.l} 0;
`;

export const StyledCardTransmissionTime = styled.span`
  display: block;
  width: 100%;
  font-size: ${(props) => props.theme.fontSize.s};
  line-height: ${(props) => props.theme.lineHeight.s};
  text-transform: uppercase;
  margin: ${(props) => props.theme.margin.m} 0
    ${(props) => props.theme.margin.m} 0;

  &.featured {
    text-align: center;
    margin: ${(props) => props.theme.margin.m} 0 0 0;
  }

  @media ${(props) => props.theme.global.breakpoints.desktop} {
    margin: ${(props) => props.theme.margin.m} 0 0 0;
  }
`;

export const StyledCardImageWrapper = styled.div`
  position: relative;
`;
