import { createSlice } from "@reduxjs/toolkit";

import { RootState } from "..";

type ApplicationState = {
  showDialog: boolean;
  hasError: boolean;
  showAllNewsletters: boolean;
};

/* initial state */
const initialState: ApplicationState = {
  showDialog: false,
  hasError: false,
  showAllNewsletters: false,
};

/* reducer */
const slice = createSlice({
  name: "application",
  initialState,
  reducers: {
    showDialog: (state) => {
      state.showDialog = true;
    },
    hideDialog: (state) => {
      state.showDialog = false;
    },
    hasError: (state) => {
      state.hasError = true;
    },
    showAllNewsletters: (state) => {
      state.showAllNewsletters = true;
    },
  },
});

export const showDialogSelector = (state: RootState): boolean =>
  state.application.showDialog;
export const hasErrorSelector = (state: RootState): boolean =>
  state.application.hasError;
export const showAllNewslettersSelector = (state: RootState): boolean =>
  state.application.showAllNewsletters;

const { actions, reducer } = slice;

// Actions
export const { showDialog, hideDialog, showAllNewsletters, hasError } = actions;

export default reducer;
