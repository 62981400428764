import styled from "styled-components";

import { fadeInAnimation, fadeOut } from "@/styles/common";

export const Button = styled.button`
  display: inline-block;
  width: 100%;
  max-width: 450px;
  height: 44px;
  border-width: 1px;
  border-style: solid;
  border-color: ${(props) => props.theme.color.highlight};
  background-color: transparent;
  border-radius: ${(props) => props.theme.radius.s};

  color: ${(props) => props.theme.color.highlight};

  font-family: ${(props) => props.theme.fontFamily.heavy};
  font-size: ${(props) => props.theme.fontSize.m};
  text-align: center;
  text-transform: uppercase;
  transition: all 0.15s ease-in-out;

  @media ${(props) => props.theme.global.breakpoints.desktop} {
    max-width: 280px;

    &:hover {
      background-color: ${(props) => props.theme.color.highlight};
      color: ${(props) => props.theme.color.white};
      transition: all 0.15s ease-in-out;
      cursor: pointer;
    }
  }

  &:focus {
    outline: 0;
  }

  &:active {
    outline: none;
  }

  &.inverted {
    background-color: ${(props) => props.theme.color.highlight};
    color: ${(props) => props.theme.color.white};
    transition: all 0.15s ease-in-out;

    &:hover {
      transition: all 0.15s ease-in-out;
      background-color: transparent;
      color: ${(props) => props.theme.color.highlight};
    }
  }

  &.slim {
    width: auto;
    height: 21px;
  }

  &.bottom-right {
    position: absolute;
    bottom: 0;
    right: 0;
  }

  &.fade-in {
    animation: ${fadeInAnimation} 0.5s ease-in-out;
  }

  &.fade-out {
    animation: ${fadeOut} 0.5s ease-in-out;
  }

  &.subscribed {
    color: ${(props) => props.theme.color.primary_brand_color};
    background-color: transparent;
    border-color: transparent;

    transition: color 0.15s ease-in-out;

    &:hover {
      transition: color 0.15s ease-in-out;
      color: ${(props) => props.theme.color.highlight};
    }
  }
`;
