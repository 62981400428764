import styled from "styled-components";

import { Views } from "@/factory/apps/NoSSOApp";
import { Colors, FontFamily } from "@/styles/styles";

export type SectionHeadlineProps = {
  display: string;
  color: Colors;
  fontFamily: FontFamily;
  fontSize: string;
  fontWeight: number;
  lineHeight: string;
  displayConfirmation?: string;

  desktop: {
    fontSize: string;
    lineHeight: string;
  };
};

export const SectionHeadline = styled.h3<{ view?: Views }>`
  display: ${(props) =>
    props?.view === Views.Confirmation
      ? props.theme.components.SectionHeader.Headline?.displayConfirmation
      : props.theme.components.SectionHeader.Headline.display};
  font-family: ${(props) =>
    props.theme.components.SectionHeader.Headline.fontFamily};
  font-size: ${(props) =>
    props.theme.components.SectionHeader.Headline.fontSize};
  font-weight: ${(props) =>
    props.theme.components.SectionHeader.Headline.fontWeight};
  line-height: ${(props) =>
    props.theme.components.SectionHeader.Headline.lineHeight};
  text-align: center;

  @media ${(props) => props.theme.global.breakpoints.desktop} {
    font-size: ${(props) =>
      props.theme.components.SectionHeader.Headline.desktop.fontSize};
    line-height: ${(props) =>
      props.theme.components.SectionHeader.Headline.desktop.lineHeight};
  }
`;

export type SectionHeadlineBorderProps = {
  display: string;
  borderColor: Colors;
  height: string;
  marginTop: string;
  width: string;
  desktop: {
    marginTop: string;
  };
};
export const SectionHeadlineBorder = styled.div`
  display: ${(props) => props.theme.components.SectionHeader.Border.display};
  width: ${(props) => props.theme.components.SectionHeader.Border.width};
  height: ${(props) => props.theme.components.SectionHeader.Border.height};
  margin-top: ${(props) =>
    props.theme.components.SectionHeader.Border.marginTop};
  background-color: ${(props) =>
    props.theme.components.SectionHeader.Border.borderColor};

  @media ${(props) => props.theme.global.breakpoints.desktop} {
    margin-top: ${(props) =>
      props.theme.components.SectionHeader.Border.desktop.marginTop};
  }
`;
