import styled from "styled-components";

export const StyledShowAllButton = styled.button`
  display: inline;
  font-family: ${(props) => props.theme.fontFamily.heavy};
  font-size: ${(props) => props.theme.fontSize.m};
  color: ${(props) => props.theme.color.white};
  background-color: transparent;
  border: none;
  margin-top: ${(props) => props.theme.margin.m};
  height: 20px;
  width: 100%;

  text-transform: uppercase;

  @media ${(props) => props.theme.global.breakpoints.desktop} {
    &:hover {
      cursor: pointer;
      color: ${(props) => props.theme.color.border};
    }
  }
`;
