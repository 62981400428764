export const getQuery = (): URLSearchParams => {
  const location = document.location.toString();
  const searchParams = new URL(location).searchParams;
  return new URLSearchParams(searchParams);
};

export const getAuthenticationToken = (): undefined | string => {
  const query = getQuery();
  const authToken = query.get("tp");

  if (!authToken) return;
  return authToken;
};
