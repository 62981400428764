import styled from "styled-components";

import { Views } from "@/factory/apps/NoSSOApp";
import { Colors, FontFamily } from "@/styles/styles";

export const HeaderSection = styled.section<{ view?: Views }>`
  position: relative;
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-bottom: ${(props) =>
    props?.view === Views.Registration || props?.view === Views.Confirmation
      ? "0px"
      : "10px"};
`;
export type HeaderBackgroundProps = {
  backgroundColor: Colors;
  backgroundImage?: string;
  desktop?: {
    justifyContent?: string;
  };
};

export const HeaderBackground = styled.div<{ view?: Views }>`
  display: flex;
  width: 100%;
  justify-content: center;
  background-color: ${(props) =>
    props.theme.header.HeaderBackground.backgroundColor};
  background-image: url("${(props) =>
    props.theme.header.HeaderBackground?.backgroundImage}");
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  @media ${(props) => props.theme.global.breakpoints.desktop} {
    justify-content: ${(props) =>
      props.theme.header.HeaderBackground.desktop?.justifyContent};
`;
export type HeaderHeadlineProps = {
  width: string;
  textAlign: string;
  desktop: {
    width?: string;
    marginLeft?: string;
    textAlign?: string;
    padding: string;
  };
};
export const HeaderHeadlineWrapper = styled.div<{ view?: Views }>`
  width: ${(props) => props.theme.header.HeaderHeadline.width};
  max-width: 700px;
  padding: 32px 12px;
  text-align: ${(props) => props.theme.header.HeaderHeadline.textAlign};
  @media ${(props) => props.theme.global.breakpoints.desktop} {
    width: ${(props) => props.theme.header.HeaderHeadline.desktop?.width};
    padding: ${(props) => props.theme.header.HeaderHeadline.desktop.padding};
    margin-left: ${(props) =>
      props.theme.header.HeaderHeadline.desktop?.marginLeft};
    text-align: ${(props) =>
      props.theme.header.HeaderHeadline.desktop?.textAlign};
  }
`;

export type HeadlineProps = {
  color: Colors;
  fontFamily: FontFamily;
  fontSize: string;
  fontWeight: number;
  lineHeight: string;
  width: string;
  margin: string;

  desktop: {
    fontSize: string;
    lineHeight: string;
    width: string;
  };
  mobileSmall?: {
    fontSize?: string;
  };
  mobile?: {
    margin?: string;
  };
};
export const Headline = styled.h1`
  width: ${(props) => props.theme.header.Headline.width};
  margin: ${(props) => props.theme.header.Headline.margin};
  color: ${(props) => props.theme.header.Headline.color};
  font-family: ${(props) => props.theme.header.Headline.fontFamily};
  font-size: ${(props) => props.theme.header.Headline.fontSize};
  font-weight: ${(props) => props.theme.header.Headline.fontWeight};
  line-height: ${(props) => props.theme.header.Headline.lineHeight};

  @media ${(props) => props.theme.global.breakpoints.desktop} {
    width: ${(props) => props.theme.header.Headline.desktop.width};
    font-size: ${(props) => props.theme.header.Headline.desktop.fontSize};
    line-height: ${(props) => props.theme.header.Headline.desktop.lineHeight};
  }

  @media ${(props) => props.theme.global.breakpoints.mobileSmall} {
    font-size: ${(props) => props.theme.header.Headline.mobileSmall?.fontSize};
  }
  @media ${(props) => props.theme.global.breakpoints.mobile} {
    margin: ${(props) => props.theme.header.Headline.mobile?.margin};
  }
`;

export type SubtitleProps = {
  backgroundColor: Colors;
  color: Colors;
  padding: string;
  fontFamily: FontFamily;
  fontSize: string;
  fontWeight: number;
  lineHeight: string;
  desktop: {
    fontSize: string;
    lineHeight: string;
  };
};
export const Subtitle = styled.h2`
  display: inline;
  padding: ${(props) => props.theme.header.Subtitle.padding};
  background-color: ${(props) => props.theme.header.Subtitle.backgroundColor};
  color: ${(props) => props.theme.header.Subtitle.color};
  font-family: ${(props) => props.theme.header.Subtitle.fontFamily};
  font-size: ${(props) => props.theme.header.Subtitle.fontSize};
  font-weight: ${(props) => props.theme.header.Subtitle.fontWeight};
  line-height: ${(props) => props.theme.header.Subtitle.lineHeight};

  @media ${(props) => props.theme.global.breakpoints.desktop} {
    font-size: ${(props) => props.theme.header.Subtitle.desktop.fontSize};
    line-height: ${(props) => props.theme.header.Subtitle.desktop.lineHeight};
  }
`;

export type HeaderTextWrapperProps = {
  backgroundColor: Colors;
  borderRadius: string;
  boxShadow: string;
  padding: string;
  mobile?: {
    width: string;
    padding: string;
    height: string;
  };
};

export const HeaderTextWrapper = styled.div<{ view: Views }>`
  display: flex;
  height: 188px;
  min-height: 95px;
  flex-direction: column;
  flex-shrink: 0;
  align-items: ${({ view }) =>
    view === Views.Registration ? "left" : "center"};
  justify-content: center;
  background-color: ${(props) =>
    props.theme.header.TextWrapper.backgroundColor};
  border-radius: ${(props) => props.theme.header.TextWrapper.borderRadius};
  box-shadow: ${(props) => props.theme.header.TextWrapper.boxShadow};

  @media ${(props) => props.theme.global.breakpoints.desktop} {
    width: calc(100vw - 24px);
    max-width: 700px;
    padding: ${(props) =>
      props.view === Views.Registration
        ? "24px 44px"
        : props.theme.header.TextWrapper.padding};
    margin-top: -48px;
  }
  @media ${(props) => props.theme.global.breakpoints.mobile} {
    height: ${(props) => props.theme.header.TextWrapper.mobile?.height};
    padding: ${(props) => props.theme.header.TextWrapper.mobile?.padding};
    margin: -48px 12px 0;
  }
`;

export type TextProps = {
  color: Colors;
  fontFamily: FontFamily;
  fontSize: string;
  lineHeight: string;
  desktop?: {
    maxWidth?: string;
    fontSize?: string;
    lineHeight?: string;
  };
};

export const Text = styled.p`
  max-width: 90%;
  color: ${(props) => props.theme.header.TextProps.color};
  font-family: ${(props) => props.theme.header.TextProps.fontFamily};
  font-size: ${(props) => props.theme.header.TextProps.fontSize};
  line-height: ${(props) => props.theme.header.TextProps.lineHeight};
  text-align: center;
  white-space: pre-wrap;
  @media ${(props) => props.theme.global.breakpoints.desktop} {
    max-width: ${(props) => props.theme.header.TextProps.desktop?.maxWidth};
    font-size: ${(props) => props.theme.header.TextProps.desktop?.fontSize};
    line-height: ${(props) => props.theme.header.TextProps.desktop?.lineHeight};
  }
`;

export type DescriptionHeadlineProps = {
  fontStyle: string;
  fontSize: string;
  fontWeight: string;
  lineHeight: string;
};
export type DescriptionListProps = {
  fontSize: string;
  padding: string;
  gap: string;
};

export type DescriptionProps = {
  gap: string;
  justifyContent: string;
  width?: string;
  alignItems: string;
};

export const DescriptionHeadline = styled.p`
  font-size: ${(props) => props.theme.header.DescriptionHeadline?.fontSize};
  font-style: ${(props) => props.theme.header.DescriptionHeadline?.fontStyle};
  font-weight: ${(props) => props.theme.header.DescriptionHeadline?.fontWeight};
  line-height: ${(props) => props.theme.header.DescriptionHeadline?.lineHeight};
`;
export const DescriptionList = styled.ul`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: ${(props) => props.theme.header.DescriptionList?.padding};
  gap: ${(props) => props.theme.header.DescriptionList?.gap};
`;
export const Description = styled.li`
  display: flex;
  align-items: ${(props) => props.theme.header.Description?.alignItems};
  justify-content: ${(props) => props.theme.header.Description?.justifyContent};
  gap: ${(props) => props.theme.header.Description?.gap};
  list-style-type: none;
`;
