import styled from "styled-components";

export const StyledHeadline = styled.h1`
  padding: ${(props) => props.theme.padding.m} 0;
  color: ${(props) => props.theme.color.text};
  font-family: ${(props) => props.theme.fontFamily.heavy};
  font-size: ${(props) => props.theme.fontSize.xl};
  line-height: ${(props) => props.theme.lineHeight.xl};

  text-align: center;
  text-transform: uppercase;

  &.m {
    padding: ${(props) => props.theme.padding.m} 0;
  }

  @media ${(props) => props.theme.global.breakpoints.desktop} {
    padding: ${(props) => props.theme.padding.xl} 0;
  }
`;
