import styled from "styled-components";

import { Colors, FontFamily } from "@/styles/styles";

export type ScheduleWrapperProps = {
  marginTop: string;
};
export const ScheduleWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-top: ${(props) => props.theme.components.Schedule.Wrapper.marginTop};
`;

export type ScheduleTextProps = {
  color: Colors;
  fontFamily: FontFamily;
  fontSize: string;
  fontWeight: number;
  lineHeight: string;
  marginLeft: string;
  desktop: {
    fontSize: string;
  };
};
export const ScheduleText = styled.span`
  margin-left: ${(props) => props.theme.components.Schedule.Text.marginLeft};
  color: ${(props) => props.theme.components.Schedule.Text.color};
  font-family: ${(props) => props.theme.components.Schedule.Text.fontFamily};
  font-size: ${(props) => props.theme.components.Schedule.Text.fontSize};
  font-weight: ${(props) => props.theme.components.Schedule.Text.fontWeight};
  line-height: ${(props) => props.theme.components.Schedule.Text.lineHeight};
  @media ${(props) => props.theme.global.breakpoints.desktop} {
    font-size: ${(props) =>
      props.theme.components.Schedule.Text.desktop.fontSize};
  }
`;
