import {
  ApiResponse,
  WhoamiApi,
  WhoamiWrapper,
} from "@weltn24/la-frontend-utils";

import { Newsletter, NewsletterUser } from "@/services/Model";
import { getDocumentUrl } from "@/utils/document";
import { log } from "@/utils/logger";

const organization = "welt";

export interface IPaperboyApi {
  allNewsletters(): Promise<ApiResponse<Newsletter[] | undefined>>;
  user(): Promise<ApiResponse<NewsletterUser>>;
  subscribe(id: number): Promise<ApiResponse<{}>>;
  unsubscribe(id: number): Promise<ApiResponse<{}>>;
}

export default (api: WhoamiApi): IPaperboyApi => {
  const allNewsletters = (): Promise<ApiResponse<Newsletter[] | undefined>> => {
    const url = `/${organization}/newsletters`;

    const whoami = WhoamiWrapper.getInstance();
    if (whoami.isLoggedIn()) {
      return api
        .authorisedGet(url, {
          "X-Referrer": getDocumentUrl(),
          "X-Client": "la-paperboy-web",
        })
        .then((response: ApiResponse<Newsletter[] | undefined>) => {
          if (!response.ok) {
            log.error(
              `Invalid authorised get allNewsletters response on page ${document.location.href}`,
              JSON.stringify(response),
            );
          }
          return response;
        });
    }

    return api
      .get(url, {
        "X-Referrer": getDocumentUrl(),
        "X-Client": "la-paperboy-web",
      })
      .then((response: ApiResponse<Newsletter[] | undefined>) => {
        if (!response.ok) {
          log.error(
            `Invalid get allNewsletters response on page ${document.location.href}`,
            JSON.stringify(response),
          );
        }
        return response;
      });
  };

  const user = (): Promise<ApiResponse<NewsletterUser>> => {
    const url = `/user`;
    return api.authorisedGet(url, {
      "X-Referrer": getDocumentUrl(),
      "X-Client": "la-paperboy-web",
    });
  };

  const subscribe = (id: number): Promise<ApiResponse<{}>> => {
    const url = `/${organization}/newsletters/${id}/subscription-authenticated`;
    return api.post(
      url,
      { source: "meine welt" },
      {
        "X-Referrer": getDocumentUrl(),
        "X-Client": "la-paperboy-web",
      },
    );
  };

  const unsubscribe = (id: number): Promise<ApiResponse<{}>> => {
    const url = `/${organization}/newsletters/${id}/subscription-authenticated`;
    return api.delete(url, null, {
      "X-Referrer": getDocumentUrl(),
      "X-Client": "la-paperboy-web",
    });
  };

  return {
    allNewsletters,
    user,
    subscribe,
    unsubscribe,
  };
};
