import React from "react";

import NewsletterBanner from "@/components/NewsletterBanner";
import SmartCTAButton from "@/components/SmartCTAButton";
import { Newsletter } from "@/services/Model";

import {
  StyledCard,
  StyledCardImage,
  StyledCardImageWrapper,
  StyledCardIntro,
  StyledCardTitle,
  StyledCardTransmissionTime,
} from "./styles";

export type CardProps = {
  newsletter: Newsletter;
  handleNewsletterClick: (newsletterID: number, subscribe: boolean) => void;
};

const TopicNewsletterCard: React.FC<CardProps> = (
  props: CardProps,
): JSX.Element => {
  const { intro, transmissionTime, title, imageURL, paidNewsletter } =
    props.newsletter;

  return (
    <StyledCard data-qa="TopicNewsletter.Card">
      <div>
        <StyledCardImageWrapper>
          <StyledCardImage src={imageURL ? imageURL : ""} />
          <NewsletterBanner
            className="absolute-bottom-left"
            dataQa="TopicNewsletter.Card.WeltPlusBanner"
            paidNewsletter={paidNewsletter}
          />
        </StyledCardImageWrapper>
        <StyledCardTitle>{title}</StyledCardTitle>
        <StyledCardIntro>{intro}</StyledCardIntro>
      </div>
      <div>
        <SmartCTAButton
          newsletter={props.newsletter}
          dataQa="TopicNewsletter.Card.Button"
          handleNewsletterClick={props.handleNewsletterClick}
        />
        <StyledCardTransmissionTime>
          {transmissionTime}
        </StyledCardTransmissionTime>
      </div>
    </StyledCard>
  );
};

export default TopicNewsletterCard;
