import styled from "styled-components";

export type SectionHeaderWrapperProps = {
  alignSelf: string;
  margin: string;
  desktop: {
    alignSelf: string;
    margin: string;
  };
};
export const SectionHeaderWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  align-self: ${(props) => props.theme.featuredNewsletters.Header.alignSelf};
  justify-content: center;
  margin: ${(props) => props.theme.featuredNewsletters.Header.margin};
  @media ${(props) => props.theme.global.breakpoints.desktop} {
    align-self: ${(props) =>
      props.theme.featuredNewsletters.Header.desktop.alignSelf};
    margin: ${(props) => props.theme.featuredNewsletters.Header.desktop.margin};
  }
`;

export const FeaturedNewsletterSection = styled.section`
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: center;
`;
