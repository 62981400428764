import styled from "styled-components";

import { fadeInAnimation } from "@/styles/common";

export const StyledNewsletterCard = styled.div`
  width: 100%;
  height: 98px;
  background: ${(props) => props.theme.color.white};
  flex-shrink: 0;

  display: flex;
  flex-direction: row;
  align-items: center;
  padding: ${(props) => props.theme.padding.s};

  &.fadeIn {
    animation: ${fadeInAnimation} 0.5s ease-in-out;
  }

  @media ${(props) => props.theme.global.breakpoints.desktop} {
    width: 463px;
  }
`;

type CardImageProps = {
  src: string;
};
export const StyledCardImage = styled.div<CardImageProps>`
  width: 160.3px;
  height: 76.65px;
  background-image: url(${(props) => props.src});
  background-repeat: no-repeat;
  background-size: cover;
  border-radius: ${(props) => props.theme.radius.s};
  flex-shrink: 0;
`;

export const StyledNewsletterCardTitle = styled.p`
  font-size: ${(props) => props.theme.fontSize.m};
  font-family: ${(props) => props.theme.fontFamily.heavy};
  color: ${(props) => props.theme.color.text};
  line-height: ${(props) => props.theme.lineHeight.m};
  text-transform: uppercase;
  text-align: left;
  margin-bottom: 0;
`;

export const StyledNewsletterCardTransmissionTime = styled.p`
  font-size: ${(props) => props.theme.fontSize.s};
  font-family: ${(props) => props.theme.fontFamily.regular};
  color: ${(props) => props.theme.color.text};
  line-height: ${(props) => props.theme.lineHeight.m};
  text-transform: uppercase;
  text-align: left;

  @media ${(props) => props.theme.global.breakpoints.mobileSmall} {
    display: none;
  }
`;

export const StyledNewsletterCardContent = styled.div`
  margin-left: ${(props) => props.theme.margin.s};
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  position: relative;
`;
