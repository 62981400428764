import { StorageService } from "@weltn24/la-frontend-utils";
import React from "react";

import { apiCookieName, getApiUrl } from "@/apiConfig";

import { StyledContainer } from "./styles";

const storageService: StorageService = StorageService.instance();

const DevelopmentInfo: React.FC = () => {
  const changeApiURL = () => {
    const newApiURL = window.prompt("Set API URL", getApiUrl());
    if (newApiURL !== null) {
      storageService.setCookieValue(apiCookieName, newApiURL);
      location.reload();
    }
  };

  return (
    <StyledContainer>
      <dl>
        <dt>Environment</dt>
        <dd>
          <pre>{__ENVIRONMENT__}</pre>
        </dd>
        <dt>API Base URL</dt>
        <dd>
          <div className={"flex"}>
            <pre>{getApiUrl()}</pre>
            <button onClick={changeApiURL} className={"button"}>
              Change API URL
            </button>
          </div>
        </dd>
        <dt>Public Path</dt>
        <dd>
          <pre>{__PUBLIC_PATH__}</pre>
        </dd>
      </dl>
    </StyledContainer>
  );
};

export default DevelopmentInfo;
