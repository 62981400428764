import styled from "styled-components";

export const StyledButton = styled.button<{ invisible?: boolean }>`
  display: inline;
  visibility: ${(props) => (props.invisible ? "hidden" : "visible")};
  opacity: ${(props) => (props.invisible ? 0 : 100)};
  position: fixed;
  bottom: 40px;
  right: 16px;
  background-color: ${(props) => props.theme.color.white};
  border: 1px solid ${(props) => props.theme.color.border};
  height: 45px;
  width: 45px;
  border-radius: 50%;
  z-index: 100;
  transition:
    opacity 0.15s ease-in-out,
    visibility 0.15s ease-in-out;

  svg {
    padding: 15px 0px;
  }

  @media ${(props) => props.theme.global.breakpoints.desktop} {
    left: calc(50vw + 400px);
    transition:
      background-color 0.15s ease-in-out,
      opacity 0.15s ease-in-out,
      visibility 0.15s ease-in-out;
    &:hover {
      transition:
        background-color 0.15s ease-in-out,
        opacity 0.15s ease-in-out,
        visibility 0.15s ease-in-out;
      cursor: pointer;
      background-color: ${(props) => props.theme.color.border};
    }
  }
`;
