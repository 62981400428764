import styled from "styled-components";

export const StyledWrapper = styled.div`
  background: rgba(237, 236, 232, 0.5);
  border-width: 1px 0px 2px 0px;
  border-style: solid;
  border-color: ${(props) => props.theme.color.border};

  display: flex;
  align-items: center;
  flex-flow: column;
  min-height: 103px;
  margin: 0 -15px;

  @media ${(props) => props.theme.global.breakpoints.desktop} {
    margin: 0 -30px;
  }
`;

export const StyledChipWrapper = styled.div`
  padding-bottom: ${(props) => props.theme.padding.m};
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  gap: ${(props) => props.theme.gap.s};
`;
