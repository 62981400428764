import { createGlobalStyle, DefaultTheme } from "styled-components";

import { defaultConfig } from "@/brandConfig/configs";

const FONT_CDN = defaultConfig.fontCDN;

export type IFontFamily = {
  bild: string;
  aubi: string;
  cobi: string;
  welt: string;
};

export type GlobalStyleProps = {
  fonts: keyof IFontFamily;
  theme: DefaultTheme;
};

export const FontFace = (
  name: string,
  src: string,
  fontWeight: string | number = "normal",
  fontStyle = "normal",
): string => {
  return `@font-face {
            font-family: "${name}";
            font-weight: ${fontWeight};
            font-style: ${fontStyle};
            font-display: fallback;
            src: url("${src}.woff2") format("woff2"),
                url("${src}.woff") format("woff"),
                url("${src}.ttf") format("truetype");
        }`;
};

const fontFaceConfig: IFontFamily = {
  bild: `
        ${FontFace("TeamLA_Gotham", `${FONT_CDN}/Gotham/Gotham-Normal`, 200)}
        ${FontFace("TeamLA_Gotham", `${FONT_CDN}/Gotham/Gotham-Bold`, 800)}
        ${FontFace(
          "TeamLA_GothamCondensed",
          `${FONT_CDN}/Gotham/GothamCondensed-Normal`,
          600,
        )}
        ${FontFace(
          "TeamLA_GothamCondensed",
          `${FONT_CDN}/Gotham/GothamCondensed-Bold`,
          700,
        )}
        ${FontFace(
          "TeamLA_GothamXNarrow",
          `${FONT_CDN}/Gotham/GothamXNarrow-Normal`,
          400,
        )}
        ${FontFace(
          "TeamLA_GothamXNarrow",
          `${FONT_CDN}/Gotham/GothamXNarrow-Bold`,
          700,
        )}
    `,
  aubi: `
        ${FontFace(
          "TeamLA_OpenSans",
          `${FONT_CDN}/OpenSans/OpenSans-Normal`,
          400,
        )}
        ${FontFace(
          "TeamLA_OpenSans",
          `${FONT_CDN}/OpenSans/OpenSans-Bold`,
          700,
        )}
        ${FontFace(
          "TeamLA_OpenSans",
          `${FONT_CDN}/OpenSans/OpenSans-Bolder`,
          800,
        )}
    `,
  cobi: `
        ${FontFace(
          "TeamLA_ProximaNova",
          `${FONT_CDN}/ProximaNova/ProximaNova-Regular`,
          400,
        )}
        ${FontFace(
          "TeamLA_ProximaNova",
          `${FONT_CDN}/ProximaNova/ProximaNova-Bold`,
          700,
        )}
          ${FontFace(
            "TeamLA_ProximaNovaCondensed",
            `${FONT_CDN}/ProximaNova/ProximaNovaCondensed-Regular`,
            400,
          )}
        ${FontFace(
          "TeamLA_ProximaNovaCondensed",
          `${FONT_CDN}/ProximaNova/ProximaNovaCondensed-Bold`,
          700,
        )}
    `,
  welt: `
    ${FontFace(
      "TeamLA_MarkOTExtralight",
      `${FONT_CDN}/Mark/Mark-Extralight`,
      200,
    )}
    ${FontFace("TeamLA_MarkOTRegular", `${FONT_CDN}/Mark/Mark-Regular`, 400)}
      ${FontFace("TeamLA_MarkOTHeavy", `${FONT_CDN}/Mark/Mark-Heavy`, 700)}
`,
};

/* helper function to handle global stylings */
export default createGlobalStyle`
  [data-widget-id="newsletter-registration-widget"],
  #la-newsletter-registration {
    min-width: 250px;
    margin: 0;
    background: ${(props: GlobalStyleProps) => props.theme.global.background};
    color: ${(props: GlobalStyleProps) => props.theme.global.colors.black};
    font-family:${(props: GlobalStyleProps) =>
      props.theme.global.fonts.fontFamily};
    font-size: 16px;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
    text-rendering: optimizeLegibility;

    /* Global font-face imports */
    ${(props: GlobalStyleProps) => fontFaceConfig[props.fonts]}

    *,
    *::before,
    *::after {
      box-sizing: border-box;
    }

    svg {
      height: auto;
      width: auto;
    }

    b,
    strong,
    h1,
    h2,
    h3,
    h4,
    h5 {
      font-weight: 600;
    }
  }
`;
