import React from "react";
import { Provider } from "react-redux";
import {
  BrowserRouter,
  HashRouter as Router,
  Route,
  Switch,
} from "react-router-dom";
import { ThemeProvider } from "styled-components";

import * as Configs from "@/brandConfig";
import Brands from "@/Brands";
import DevelopmentInfo from "@/components/DevelopmentInfo";
import ErrorBoundary from "@/components/NoSSOErrorBoundary";
import * as Contents from "@/content/";
import { NoSSODefaultContent } from "@/content/Model";
import { AppFactoryProps } from "@/factory";
import { HandleProps } from "@/factory/AppFactory";
import { Routes } from "@/routes";
import { isProduction } from "@/services/Environment";
import { createStore } from "@/store/noSSO";
import GlobalStyles, { IFontFamily } from "@/styles/GlobalStyles";
import * as Themes from "@/styles/themes";
import Confirmation from "@/views/noSSO/confirmation";
import Edited from "@/views/noSSO/edited";
import Registration from "@/views/noSSO/registration";
import Verified from "@/views/noSSO/verified";

// create global context - set bild content as default
export const NoSSOGlobalContext = React.createContext<NoSSODefaultContent>(
  Contents[Brands.Bild],
);
export const ConfigContext = React.createContext(Configs[Brands.Bild]);

export const enum Views {
  Registration = "registration",
  Confirmation = "confirmation",
  Edited = "edited",
  Verified = "verified",
  Error = "error",
}

const NoSSOAppRouter: React.FC = (): JSX.Element => (
  <Router>
    <Switch>
      <Route path={Routes.Confirmation} component={Confirmation} />
      <Route path={Routes.Verified} component={Verified} />
      <Route path={Routes.Edited} component={Edited} />
      <Route path={Routes.Default} component={Registration} />
    </Switch>
  </Router>
);

const NoSSOApp: React.FC<AppFactoryProps> = (props): JSX.Element => {
  const { brand } = props;
  const fonts = brand as keyof IFontFamily;
  const theme = Themes[fonts];
  const content = Contents[fonts] as NoSSODefaultContent;
  const brandConfig = Configs[fonts];

  return (
    <Provider store={createStore()}>
      <ThemeProvider theme={theme}>
        <ConfigContext.Provider value={brandConfig}>
          <NoSSOGlobalContext.Provider value={content}>
            <GlobalStyles fonts={fonts} />
            <ErrorBoundary context={brandConfig.ErrorBoundaryContext}>
              <BrowserRouter basename={`${__PUBLIC_PATH__}/`}>
                <NoSSOAppRouter />
                {isProduction() || <DevelopmentInfo />}
              </BrowserRouter>
            </ErrorBoundary>
          </NoSSOGlobalContext.Provider>
        </ConfigContext.Provider>
      </ThemeProvider>
    </Provider>
  );
};

const factoryHandler: HandleProps = {
  canHandle: (props) =>
    [Brands.Aubi, Brands.Cobi, Brands.Bild].includes(props.brand),
  renderComponent: (props) => <NoSSOApp brand={props.brand} />,
};

export default {
  ...NoSSOApp,
  ...factoryHandler,
};
