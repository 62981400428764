import React from "react";
import styled from "styled-components";

import { ConfigContext } from "@/factory/apps/NoSSOApp";
import { SpinningAnimation } from "@/styles/common";

export type SpinnerProps = {
  name: string;
};

const StyledSpinner = styled.img`
  animation: ${SpinningAnimation};
  animation-duration: 1s;
  animation-iteration-count: infinite;
`;

export const Spinner: React.FC<SpinnerProps> = (
  props: SpinnerProps,
): JSX.Element => {
  const { iconSourceURL } = React.useContext(ConfigContext);
  if (props.name) {
    return <StyledSpinner src={`${iconSourceURL}${props.name}.svg`} />;
  }
  return <React.Fragment />;
};
