import React from "react";

import LinkButton from "@/components/LinkButton";
import { NoSSOGlobalContext } from "@/factory/apps/NoSSOApp";

import { StyledButtonWrapper, StyledErrorSection } from "./styles";

const reloadPage = () => {
  window.location.reload();
};

const SectionErrorUI: React.FC = (): JSX.Element => {
  const { FallbackTextsSectionUI } = React.useContext(NoSSOGlobalContext);
  return (
    <StyledErrorSection data-qa="SectionErrorUI">
      <h3>{FallbackTextsSectionUI.title}</h3>
      <p>{FallbackTextsSectionUI.text}</p>
      <StyledButtonWrapper>
        <LinkButton
          iconName={FallbackTextsSectionUI.buttonIconName}
          text={FallbackTextsSectionUI.buttonText}
          onButtonClick={reloadPage}
        />
      </StyledButtonWrapper>
    </StyledErrorSection>
  );
};

export default SectionErrorUI;
