import FeaturedNewsletterCard from "./FeaturedNewsletterCard";
import {
  FeaturedNewsletterCardWrapperProps,
  FeaturedNewsletterContentWrapperProps,
  FeaturedNewsletterTitleProps,
  ImageTitleProps,
  ImageWrapperProps,
} from "./styles";

export type FeaturedNewsletterCardStyleProps = {
  CardWrapper: FeaturedNewsletterCardWrapperProps;
  ContentWrapper: FeaturedNewsletterContentWrapperProps;
  ImageWrapper: ImageWrapperProps;
  ImageTitle: ImageTitleProps;
  Title: FeaturedNewsletterTitleProps;
};
export default FeaturedNewsletterCard;
