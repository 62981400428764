import { createSlice } from "@reduxjs/toolkit";

import { NewsletterUser } from "@/services/Model";

import { RootState } from "..";

type UserState = {
  user: NewsletterUser;
  isLoading: boolean;
  isError: boolean;
};

/* initial state */
const initialState: UserState = {
  user: {} as NewsletterUser,
  isError: false,
  isLoading: false,
};

/* reducer */
const slice = createSlice({
  name: "user",
  initialState,
  reducers: {
    fetchUser: (state) => {
      state.isLoading = true;
      state.isError = false;
      state.user = {} as NewsletterUser;
    },
    fetchUserSuccess: (state, action) => {
      state.isLoading = false;
      state.isError = false;
      state.user = action.payload as NewsletterUser;
    },
    fetchUserError: (state) => {
      state.isLoading = false;
      state.isError = true;
      state.user = {} as NewsletterUser;
    },
  },
});

export const fetchUserSelector = (state: RootState): boolean =>
  state.user.isLoading;

export const userSelector = (state: RootState): NewsletterUser =>
  state.user.user;

const { actions, reducer } = slice;

// Actions
export const { fetchUser, fetchUserError, fetchUserSuccess } = actions;

export default reducer;
