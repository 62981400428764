import React, { ReactNode } from "react";
import SVG from "react-inlinesvg";

import { ConfigContext } from "@/factory/apps/NoSSOApp";
import { Icons } from "@/styles/styles";

import { ScheduleText, ScheduleWrapper } from "./styles";

type ScheduleProps = {
  children: ReactNode;
};
const Schedule: React.FC<ScheduleProps> = (
  props: ScheduleProps,
): JSX.Element => {
  const { iconSourceURL } = React.useContext(ConfigContext);
  return (
    <ScheduleWrapper>
      <SVG src={`${iconSourceURL}${Icons.Clock}.svg`} />
      <ScheduleText>{props.children}</ScheduleText>
    </ScheduleWrapper>
  );
};
export default Schedule;
