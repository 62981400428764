import styled from "styled-components";

import { Colors, FontFamily } from "../../styles/styles";

export type TextComponentProps = {
  color: Colors;
  lineHeight: string;
  fontFamily: FontFamily;
  fontSize: string;
  margin: string;
};
export const TextComponent = styled.p`
  margin: ${(props) => props.theme.components.Text.margin};
  color: ${(props) => props.theme.components.Text.color};
  font-family: ${(props) => props.theme.components.Text.fontFamily};
  font-size: ${(props) => props.theme.components.Text.fontSize};
  line-height: ${(props) => props.theme.components.Text.lineHeight};
`;
