import styled from "styled-components";

export const StyledDialogContainer = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  width: 100vw;
  height: 100vh;
  opacity: 0;
  display: none;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 1000;

  &.visible {
    opacity: 1;
    display: block;
  }
`;

export const StyledDialogHeader = styled.header`
  width: 100%;
  height: 50px;

  display: flex;
  justify-content: space-between;
`;

export const StyledDialogWrapper = styled.div`
  position: absolute;
  height: 440px;
  width: 343px;
  left: calc(50vw - 171px);
  top: calc(50vh - 220px);
  background: #023c5e;
  box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.25);

  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: space-between;
  padding: ${(props) => props.theme.padding.l};

  @media ${(props) => props.theme.global.breakpoints.desktop} {
    width: 632px;
    left: calc(50vw - 316px);
  }
`;

export const StyledDialogContent = styled.div`
  max-width: 480px;
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
`;

export const StyledDialogFooter = styled.footer`
  width: 100%;
  height: 50px;

  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  color: ${(props) => props.theme.color.white};
  font-size: ${(props) => props.theme.fontSize.s};
  line-height: ${(props) => props.theme.lineHeight.s};
`;

export const StyledDialogFooterLinkWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const StyledDialogHeaderButton = styled.button`
  border: none;
  background-color: transparent;
  height: 22px;

  :hover {
    cursor: pointer;
  }
`;

export const StyledDialogFooterDivider = styled.span`
  margin: 0 8px;
`;
