import { PayloadAction } from "@reduxjs/toolkit";
import { ApiResponse, isOk } from "@weltn24/la-frontend-utils";
import {
  call,
  getContext,
  GetContextEffect,
  put,
  takeEvery,
  takeLatest,
} from "redux-saga/effects";

import { IPaperboyApi } from "@/services/api/PaperboyApi";
import { Newsletter } from "@/services/Model";
import { trackSubscribe, trackUnsubscribe } from "@/services/Tracking";

import { hasError } from "../application/slice";
import {
  fetchNewsletters,
  fetchNewslettersError,
  fetchNewslettersSuccess,
  newsletterHadSubscribeError,
  newsletterHadUnSubscribeError,
  resetSubscribeSuccess,
  subscribeNewsletter,
  subscribeNewsletterError,
  subscribeNewsletterSuccess,
  unSubscribeNewsletter,
  unSubscribeNewsletterError,
  unSubscribeNewsletterSuccess,
} from "./slice";

function delay(ms: number) {
  return new Promise((resolve) => setTimeout(resolve, ms));
}

export function* getNewsletters() {
  try {
    const paperboyApi: IPaperboyApi = yield getContext("paperboyApi");
    const request: Promise<ApiResponse<Newsletter[] | undefined>> | null =
      yield paperboyApi.allNewsletters().then(isOk);
    const data: Newsletter[] | undefined = yield request;

    if (data) {
      yield put(fetchNewslettersSuccess(data));
    } else {
      yield put(fetchNewslettersError());
    }
  } catch (error) {
    yield put(fetchNewslettersError(error));
    yield put(hasError());
  }
}

export function* postNewsletterSubscription(
  action: PayloadAction<{ newsletterID: number }>,
) {
  const { newsletterID } = action.payload;
  try {
    const paperboyApi: IPaperboyApi = yield getContext("paperboyApi");
    yield paperboyApi.subscribe(newsletterID).then(isOk);
    trackSubscribe(newsletterID);

    yield put(subscribeNewsletterSuccess({ newsletterID }));

    // delay hide thank you note again
    yield call(delay, 1500);
    yield put(resetSubscribeSuccess({ newsletterID }));
  } catch (error) {
    yield put(subscribeNewsletterError({ newsletterID }));

    // delay to display the subscribe button again for retry
    yield call(delay, 1500);
    yield put(newsletterHadSubscribeError({ newsletterID }));
  }
}

export function* deleteNewsletterSubscription(
  action: PayloadAction<{ newsletterID: number }>,
) {
  const { newsletterID } = action.payload;
  try {
    const paperboyApi: IPaperboyApi = yield getContext("paperboyApi");
    yield paperboyApi.unsubscribe(newsletterID).then(isOk);
    trackUnsubscribe(newsletterID);

    yield put(unSubscribeNewsletterSuccess({ newsletterID }));
  } catch (error) {
    yield put(unSubscribeNewsletterError({ newsletterID }));

    // delay to display the unsubscribe button again for retry
    yield call(delay, 1500);
    yield put(newsletterHadUnSubscribeError({ newsletterID }));
  }
}

export function* watchFetchNewsletters(): Generator<
  GetContextEffect | Promise<ApiResponse<Newsletter[]>> | unknown
> {
  yield takeLatest(fetchNewsletters, getNewsletters);
}

export function* watchSubscribeNewsletter(): Generator<
  GetContextEffect | Promise<{}> | unknown
> {
  yield takeEvery(subscribeNewsletter, postNewsletterSubscription);
}

export function* watchUnSubscribeNewsletter(): Generator<
  GetContextEffect | Promise<{}> | unknown
> {
  yield takeEvery(unSubscribeNewsletter, deleteNewsletterSubscription);
}
