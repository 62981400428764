import { getBaseURL } from "@/apiConfig";

import { BrandConfig } from "./Model";

export const defaultConfig = {
  dataProtectionNoticeURL: "https://www.axelspringer.com/de/datenschutzhinweis",
  dataProtectionURL: "https://www.axelspringer.com/de/datenschutzerklaerung",
  fontCDN: "https://assets.la.spring-media.de/fonts",
  requestTimeout: 30000,
};

export const bild: BrandConfig = {
  ...defaultConfig,
  homePageURL: "https://www.bild.de",
  iconSourceURL: `${getBaseURL()}/assets/bild/`,
  subscriptionSource: "Bild registration page",
  ErrorBoundaryContext: "Bild Newsletter Registration Page",
  hasBrandIcon: true,
  hasOptInLinks: true,
  hasTopNewslettersNumber: true,
  hasCardBackgroundImage: false,
};

export const aubi: BrandConfig = {
  ...defaultConfig,
  homePageURL: "https://www.autobild.de",
  iconSourceURL: `${getBaseURL()}/assets/aubi/`,
  subscriptionSource: "Auto-Bild registration page",
  ErrorBoundaryContext: "Auto Bild Newsletter Registration Page",
  hasBrandIcon: false,
  hasOptInLinks: false,
  hasTopNewslettersNumber: false,
  hasCardBackgroundImage: false,
};

export const cobi: BrandConfig = {
  ...defaultConfig,
  homePageURL: "https://www.computerbild.de",
  iconSourceURL: `${getBaseURL()}/assets/cobi/`,
  subscriptionSource: "Computer-Bild registration page",
  ErrorBoundaryContext: "Computer Bild Newsletter Registration Page",
  hasBrandIcon: false,
  hasOptInLinks: false,
  hasTopNewslettersNumber: false,
  hasCardBackgroundImage: true,
};

export const welt: BrandConfig = {
  ...defaultConfig,
  homePageURL: "https://www.welt.de",
  iconSourceURL: `${getBaseURL()}/assets/welt/`,
  subscriptionSource: "Welt Newsletter Registration Page",
  ErrorBoundaryContext: "Welt Newsletter Registration Page",
  hasBrandIcon: false,
  hasOptInLinks: false,
  hasTopNewslettersNumber: false,
  hasCardBackgroundImage: false,
};

export enum WeltTopics {
  Recommended = "recommended",
  Exclusive = "exclusive",
  Politics = "politics",
  Economics = "economics",
  Lifestyle = "lifestyle",
  Updates = "updates",
}

export const weltTopicHeadlineMapper = {
  [WeltTopics.Recommended]: "Newsroom Empfehlungen",
  [WeltTopics.Exclusive]: "WELTplus Exklusiv",
  [WeltTopics.Politics]: "Politik",
  [WeltTopics.Economics]: "Wirtschaft",
  [WeltTopics.Lifestyle]: "Leben & Lifestyle",
  [WeltTopics.Updates]: "Nichts verpassen",
};

export const weltTopicOrder = [
  WeltTopics.Recommended,
  WeltTopics.Exclusive,
  WeltTopics.Politics,
  WeltTopics.Economics,
  WeltTopics.Lifestyle,
  WeltTopics.Updates,
];
