import React from "react";

import { NoSSOGlobalContext, Views } from "@/factory/apps/NoSSOApp";
import Header from "@/noSSOPartials/Header";

export type FallbackProps = {
  onButtonClick: () => void;
};

const FallbackUI: React.FC<FallbackProps> = (
  props: FallbackProps,
): JSX.Element => {
  const { FallbackTexts } = React.useContext(NoSSOGlobalContext);

  return (
    <React.Fragment>
      <Header
        data-qa="FallbackUI"
        subtitle={FallbackTexts.subtitle}
        title={FallbackTexts.title}
        text={FallbackTexts.text}
        view={Views.Error}
        button={{
          iconName: FallbackTexts.buttonIconName,
          text: FallbackTexts.buttonText,
          onButtonClick: props.onButtonClick,
        }}
      />
    </React.Fragment>
  );
};

export default FallbackUI;
