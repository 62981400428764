import OtherNewslettersCard from "./OtherNewslettersCard";
import {
  CardStyleProps,
  IconWrapperProps,
  InfoboxModalProps,
  InfoIconButtonProps,
  ScheduleProps,
  SponsoredProps,
  TextProps,
  TextWrapperProps,
  TitleProps,
  TitleWrapperProps,
} from "./styles";

export type OtherNewslettersCardStyleProps = {
  CardStyle: CardStyleProps;
  Sponsored: SponsoredProps;
  IconWrapper: IconWrapperProps;
  TextWrapper: TextWrapperProps;
  TitleWrapper: TitleWrapperProps;
  Title: TitleProps;
  InfoIconButton: InfoIconButtonProps;
  InfoboxModal: InfoboxModalProps;
  Schedule: ScheduleProps;
  Text: TextProps;
};

export default OtherNewslettersCard;
